import React, { useContext } from 'react';
import {Tab, Tabs} from "@mui/material";
import {AppContext} from './AppContext';

const tabStyles = {
    container: {
        flex: 1, textAlign: 'center'
    }
};

const badgeStyles = {
    container: {
        flex: 1, textAlign: 'center'
    }, badgeContainer: {
        position: 'relative', display: 'inline-block', width:'100%', minWidth: 'auto'
    }, badge: {
        position: 'absolute',
        top: 8,
        right: 8,
        width: 20,
        height: 20,
        background: '#FF003D',
        borderRadius: '50%',
        zIndex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }, badgeText: {
        color: '#fff',
        fontSize: 12,
        fontFamily: 'Manrope',
        fontWeight: '500',
        lineHeight: '20px',
        wordWrap: 'break-word'
    }
};

const MyTab = ({badge, ...otherProps}) => (
    <div style={tabStyles.container}>
        {badge > 0 && (<div style={badgeStyles.badge}>
            <div style={badgeStyles.badgeText}>
                {badge}
            </div>
        </div>)}
        <Tab {...otherProps} style={{width: '100%', minWidth: 'auto'}}/>
        </div>);

const MyTabs = ({handleTabChange, activeTab, unreadMessageCount}) => {

    const [tab, setTab] = React.useState(activeTab || 'tasks');
    const { setCurrentTab } = useContext(AppContext); 

    React.useEffect(() => {
        setTab(activeTab);     
    }, [activeTab]);

    const internalHandleTabChange = (event, newValue) => {
        const newTabValue = newValue;
        setTab(newTabValue);
        setCurrentTab(newTabValue);
        handleTabChange(event, newTabValue);   
        
    };

    const getIconStyles = (activeTab, currentTab) => {
        const isActive = activeTab === currentTab;
        return {
            opacity: isActive ? 1 : 0.5, width: 20, height: 20, paddingTop: 0
        };
    };

    const getLabelStyles = () => {
        return {
            textTransform: 'none', fontSize: '12px'
        };
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const calculateUnreadCounts = () => {

        const memberID = localStorage.getItem("memberID")
        const totalUnread = Object.entries(unreadMessageCount)
        .filter(([key]) => key === 'General' || key.includes(`mem_${memberID}`))
        .reduce((sum, [_, value]) => sum + parseInt(value, 10), 0);

        return totalUnread;

    };

    let tabsMap = ['tasks', 'done', 'chat'];

    return (<Tabs
        value={tab}
        onChange={internalHandleTabChange}
        style={{
            display: 'flex', justifyContent: 'space-around', width: '100%'
        }}
    >
        {tabsMap.map((currentTab) => (<MyTab
            key={currentTab}
            icon={<img
                alt={currentTab}
                src={`./app-icons/${currentTab}.svg`}
                style={getIconStyles(tab, currentTab)}
            />}
            label={<span style={getLabelStyles()}>
                        {capitalizeFirstLetter(currentTab)}
                    </span>}
            value={currentTab}
            wrapped
            badge={calculateUnreadCounts()}
        />))}
    </Tabs>);
};

export default MyTabs;