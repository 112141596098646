import React, {Component} from 'react';
import Chat from './Chat';
import {AppContext} from '../AppContext';

const ChatWrapper = ({Component}) => {
    return <Component/>;
};

class ChatWrapperClass extends Component {
    static contextType = AppContext;


    componentWillUnmount() {
        this.context.handleCloseWebSocket()
    }


    render() {
        return (<Chat
            chatTask={this.context.chatTask}
            ws={this.context.ws}
            chatMessages={this.context.chatMessages}
            isWebSocketOpen={this.context.isWebSocketOpen}
            members={this.context.members}
            staff={this.context.staff}
            onCloseChat={this.context.handleCloseChat}
            handleFileUpload={this.context.handleFileUpload}
            handleDeleteMessage={this.context.handleDeleteMessage}
            handleEditMessage={this.context.handleEditMessage}
        />);
    }
}

const WrappedChat = () => <ChatWrapper Component={ChatWrapperClass}/>;
export default WrappedChat;