import React from 'react';
import {Button} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import PinIcon from '@mui/icons-material/PushPinOutlined';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import DialogContent from "@mui/material/DialogContent";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {AppContext} from './AppContext';

const baseURL = process.env.REACT_APP_API_BASE_URL;

class AlertDialog extends React.Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.state = {
            open: false, secondDialogOpen: false, swapDialogOpen: false, isDeleting: false,
        };
    }

    handleClickOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    handleDeleteClick = () => {
        this.setState({open: false, secondDialogOpen: true});
    };

    handleSecondDialogClose = () => {
        this.setState({secondDialogOpen: false});
    };

    handleSwapClick = (taskIdToBePinned) => {
        this.setState({open: false, swapDialogOpen: true});
        window.scrollTo({
            top: 0, behavior: 'smooth',
        });
        this.props.onChildStateChange(true, taskIdToBePinned);

    };

    handleSwapDialogClose = () => {
        this.setState({swapDialogOpen: false});
        this.props.onChildStateChange(false);

    };

    handlePin = (taskId) => {
        this.props.handlePin(taskId);
        this.setState({open: false});
    };

    handleRemovePin = (taskId) => {
        this.props.handleRemovePin(taskId);
        this.setState({open: false});
    };

    handleHold = (taskId) => {
        this.props.handleHold(taskId);
        this.setState({open: false});
    };

    handleUnhold = (taskId) => {
        this.props.handleUnhold(taskId);
        this.setState({open: false});
    };

    handlePreventPin = () => {
        this.setState({open: false});
    }

    handleDeleteTask = (taskId) => {
        this.setState({isDeleting: true});
        const requestOptions = {
            method: "POST", headers: {
                "Content-type": "application/json; charset=UTF-8",
            }, body: JSON.stringify({
                taskId: taskId,
            }),
        };

        fetch(`${baseURL}/utils/removeClientTask.php`, requestOptions).then((response) => {
            if (response.ok) {
                this.props.onDeleteTask(taskId);
                this.handleSecondDialogClose();
                this.context.clearUnreadMessagesFromServer(taskId)
            } else {
                throw new Error("Failed to remove task");
            }
        })
            .catch((error) => {
                console.error(error);
            });
    };


    render() {
        const {open, secondDialogOpen, isDeleting} = this.state;
        const {itemId, maxPinnedItems, currentPinnedCount, isPinned, isHold, isInfoNeeded} = this.props;

        const styles = {
            dialogTitle: {
                textAlign: 'center',
                color: 'white',
                fontSize: 16,
                fontFamily: 'Manrope',
                fontWeight: '500',
                wordWrap: 'break-word'
            },
            dialogContent: {
                display: "flex",
                alignItems: "center",
                backgroundColor: "#48484C",
                borderRadius: "10px",
                padding: "10px",
                paddingRight: "100px",
                marginBottom: "10px",
                fontFamily: "Manrope",
                cursor: "pointer",
                color: "#fff",
            },
            button: {
                color: "#ffffff",
                marginRight: "8px",
                backgroundColor: "#48484D",
                fontSize: "12px",
                textTransform: "none",
            },
            deleteButton: {color: "#ffffff", backgroundColor: "#FF003D", fontSize: "12px", textTransform: "none"},
            dialogContainer: {
                display: "flex",
                alignItems: "center",
                borderRadius: "10px",
                padding: "10px",
                paddingRight: "20px",
                marginBottom: "10px",
                fontFamily: "Manrope",
                cursor: "pointer",
            },
            dialogIcon: {
                color: "#ffffff", marginRight: "10px",
            },

        }
        const deleteButtonDisabledStyle = {
            ...styles.deleteButton, backgroundColor: 'grey',
        };


        const PinContentContainer = ({
                                         itemId,
                                         isPinned,
                                         currentPinnedCount,
                                         maxPinnedItems,
                                         handlePin,
                                         handleRemovePin,
                                         handleSwapClick
                                     }) => {
            const handleContentClick = () => {
                if (isHold) {
                    this.handlePreventPin();
                } else {
                    handlePinStatus();
                }
            };

            const handlePinStatus = () => {
                if (isPinned) {
                    handleRemovePin(itemId);
                } else if (currentPinnedCount < maxPinnedItems) {
                    handlePin(itemId);
                } else {
                    handleSwapClick(itemId);
                }
            };

            const pinText = isPinned ? "Move back to Queue" : "Move Task to Top";

            return (<div
                style={{...styles.dialogContainer, backgroundColor: !isHold? "#4D4D4D": '#A9A9A9', opacity:!isHold && !isInfoNeeded ? "1": '0.5'}}
                onClick={!isHold && !isInfoNeeded? handleContentClick : null} 
            >
                <PinIcon style={styles.dialogIcon}/>
                <span style={{color: "#FFFFFF"}}>{pinText}</span>
            </div>);
        };

        const HoldContentContainer = ({itemId, isHeld, handleHold, handleUnhold, isPinned, handleRemovePin}) => {
            const handleContentClick = () => {
                if (isPinned && !isHeld) {
                    handleHold(itemId);
                    handleRemovePin(itemId);
                } else if (!isPinned && !isHeld) {
                    handleHold(itemId);
                } else {
                    handleUnhold(itemId);
                }
            };

            const containerStyle = {...styles.dialogContainer, backgroundColor: "#4D4D4D"};
            const textStyle = {color: "#FFFFFF"};
            const holdText = isHeld ? "Take off Hold" : "Put On Hold";

            return (<div style={containerStyle} onClick={handleContentClick}>
                <CropSquareIcon style={styles.dialogIcon}/>
                <span style={textStyle}>{holdText}</span>
            </div>);
        };


        const DeleteDialogContent = ({handleDeleteClick}) => (
            <div style={{...styles.dialogContainer, backgroundColor: "#ff003d"}} onClick={handleDeleteClick}>
                <DeleteIcon style={styles.dialogIcon}/>
                <span style={{color: "#FFFFFF"}}>Delete Task</span>
            </div>);

        return (<>
            <MoreVertIcon onClick={this.handleClickOpen} style={{color: "#ffffff"}}/>
            <Dialog open={open} onClose={this.handleClose} aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    paperprops={{style: {borderRadius: "10px", backgroundColor: "#242426", boxShadow: "none"}}}>
                <DialogTitle id="alert-dialog-title" style={styles.dialogTitle}>Options</DialogTitle>
                <DialogContent>
                    <PinContentContainer
                        itemId={itemId}
                        isPinned={isPinned}
                        currentPinnedCount={currentPinnedCount}
                        maxPinnedItems={maxPinnedItems}
                        handlePin={this.handlePin}
                        handleRemovePin={this.handleRemovePin}
                        handleSwapClick={this.handleSwapClick}
                    />
                    <HoldContentContainer
                        itemId={itemId}
                        isHeld={isHold}
                        handleHold={this.handleHold}
                        handleUnhold={this.handleUnhold}
                        isPinned={isPinned}
                        handleRemovePin={this.handleRemovePin}
                    />
                    <DeleteDialogContent handleDeleteClick={this.handleDeleteClick}/>
                </DialogContent>
            </Dialog>
            <Dialog open={secondDialogOpen} onClose={this.handleSecondDialogClose}
                    aria-labelledby="second-dialog-title" aria-describedby="second-dialog-description"
                    paperprops={{style: {borderRadius: "10px", backgroundColor: "#242426", boxShadow: "none"}}}>
                <DialogTitle id="second-dialog-title" style={styles.dialogTitle}>{"Delete task?"}</DialogTitle>
                <DialogContent>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <Button onClick={this.handleSecondDialogClose} style={styles.button}>Cancel</Button>
                        <Button onClick={() => this.handleDeleteTask(itemId)}
                                style={isDeleting ? deleteButtonDisabledStyle : styles.deleteButton}
                                disabled={isDeleting}>Delete</Button>
                    </div>
                </DialogContent>
            </Dialog>

        </>);
    }
}

export default AlertDialog;