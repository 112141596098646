import React from 'react';
import {Button, ListItem} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import List from "@mui/material/List";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";

class CategoryAlertDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false, selectedCategory: "All Categories",
        };
    }

    handleClickOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    handleRadioChange = (event) => {
        const selectedCategory = event.target.value;
        this.setState({selectedCategory});
        this.props.onCategoryChange(selectedCategory);
    };

    render() {
        const {open, selectedCategory} = this.state;
        //todo: introduce an Airtable fetch for categories
        const categoriesList = ["All Categories", "Activities", "Admin Finance and Legal", "Birthdays and Events", "Dining", "Education", "Gifts and Shopping", "Health and Wellbeing", "Holidays and Travel", "Home and Garden", "Family", "Pets", "Real Estate", "Vehicles"];
        return (<div style={{marginBottom: "12px" , marginTop:"12px"}}>

            <div style={{
                paddingLeft: 12,
                paddingRight: 12,
                paddingTop: 10,
                paddingBottom: 10,
                background: '#202020',
                borderRadius: 20,
                overflow: 'hidden',
                border: '0.50px solid',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 12,
                display: 'inline-flex'
            }}>
                <div style={{
                    color: 'white', fontSize: 13, fontFamily: 'Manrope', fontWeight: '500', wordWrap: 'break-word'
                }}>{selectedCategory}</div>
            </div>

            <img
                onClick={this.handleClickOpen}
                src="./app-icons/filter.png"
                alt="Filter"
                style={{
                    float: "right",
                    paddingRight: 12,
                    paddingTop: 10,
                    paddingBottom: 10,
                    display: "flex",
                    alignItems: "right",
                }}
            />

            <Dialog
                onClose={this.handleClose}
                open={open}
                PaperProps={{
                    style: {
                        borderRadius: "10px", backgroundColor: "#242426", boxShadow: "none",
                    },
                }}
                sx={{
                    "& .MuiDialog-paper": {
                        maxWidth: "300px", width: "100%", height: "70%"
                    },
                }}
            >
                <DialogTitle style={{color: "#ffffff"}} align="center">
                    Task Categories
                </DialogTitle>
                <List sx={{pt: 0}}>
                    <RadioGroup
                        aria-label="task-category"
                        name="task-category"
                        value={selectedCategory}
                        onChange={this.handleRadioChange}
                    >
                        {categoriesList.map((category, index) => (<React.Fragment key={index}>
                            <ListItem>
                                <ListItemText
                                    primary={category}
                                    primaryTypographyProps={{
                                        style: {
                                            color: "#ffffff",
                                        },
                                    }}
                                />
                                <ListItemSecondaryAction>
                                    <Radio
                                        edge="end"
                                        sx={{
                                            color: "#FFFFFF", "&.Mui-checked": {
                                                color: "#2F98A3",
                                            }, "&:hover": {
                                                boxShadow: "0",
                                            }, "&:focus": {
                                                boxShadow: "0",
                                            },
                                        }}
                                        value={category}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>

                            <Divider
                                sx={{
                                    backgroundColor: "#53535B", width: "93%", margin: "auto",
                                }}
                            />
                        </React.Fragment>))}
                    </RadioGroup>
                </List>
                <DialogActions sx={{justifyContent: "center"}}>
                    <Button
                        onClick={this.handleClose}
                        style={{
                            color: "#ffffff", backgroundColor: "#2F98A3", fontSize: "12px",
                        }}
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>);
    }
}

export default CategoryAlertDialog;