/* global PushNotification, universalLinks */
import React from "react";
import packageInfo from '../package.json';
import {HashRouter as Router, Route, Routes} from 'react-router-dom';
import {createRoot} from "react-dom/client";
import MyTabs from './components/Tabs';
import Referral from './components/Referral';
import ChatWrapper from './components/ChatSystem/ChatWrapper';
import TaskCard from './components/TaskCard';
import TaskInputForm from './components/TaskInputForm';
import PaymentDetailsSection from './components/PaymentDetailsSection';
import CategoryAlertDialog from './components/CategoryAlertDialog';
import Notifications from "./components/Notifications.js";
import {Button, CircularProgress, Grid, Paper, TextField, ThemeProvider, Typography, Box, Dialog} from "@mui/material";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import "@fontsource/manrope";
import "./styles/style.css";
import MUIStyles from './styles/MUIStyles';
import LoginIcon from '@mui/icons-material/Login';
import {AppContext, AppContextProviderWithNavigate} from './components/AppContext.js';
import TagManager from 'react-gtm-module'
import Navbar from "./components/Navbar.js";

const tagManagerArgs = {
    gtmId: 'GTM-WSFQ8RMW'
}

TagManager.initialize(tagManagerArgs)
const baseURL = process.env.REACT_APP_API_BASE_URL;

const styles = {
    root: {
        backgroundColor: "#121214",
    }, container: {
        display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh',
    }, formSection: {
        flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
    }, exploreLinkSection: {
        flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
    }, tilesContainer: {
        display: 'flex', justifyContent: 'space-between', width: '100%',
    }, tile: {
        flex: 1, margin: '5px', padding: '20px', backgroundColor: '#f0f0f0', color: '#333', textAlign: 'center',
    }, footerSection: {
        flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
    }, form: {
        width: '100%', padding: 12, display: "flex", alignItems: "center",
    }, link: {
        color: 'white'
    }, textField: {
        background: '#202020',
        borderRadius: 25,
        overflow: 'hidden',
        border: '0.50px #48484D solid',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 8,
        display: 'inline-flex',
        fontFamily: "Manrope",
        fontWeight: "normal",
        fontSize: "clamp(.6rem, 2vw, 1rem)",
        flexGrow: 1,
    }, submitButton: {
        minWidth: 50,
        minHeight: 50,
        background: '#2F98A3',
        borderRadius: "50%",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 8,
        marginLeft: "8px",
    },  priorityText: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
        paddingBottom: "12px",
        paddingTop: "12px",
        fontFamily: "Manrope",
        fontSize: 16,
    },
};

class RoutingApp extends React.Component {
    render() {
        return (<Router>
            <AppContextProviderWithNavigate>
                <Routes>
                    <Route path="/" element={<App tab="tasks"/>}/>
                    <Route path="/chat" element={<ChatWrapper/>}/>
                    <Route path="/refer" element={<Referral/>}/>
                    <Route path="/notifications" element={<Notifications/>}/>
                    <Route path="/newTask" element={<TaskInputForm/>}/>
                </Routes>
            </AppContextProviderWithNavigate>
        </Router>);
    }
}

class App extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.pinnedListRef = React.createRef();

        const token = localStorage.getItem('token');
        const isAuthenticated = !!token;

        this.state = {
            appVersion: packageInfo.version,
            memberEmail: '',
            password: '',
            newPassword: '',
            confirmPassword: '',
            authErrorMessage: '',
            noPassword: true,
            authenticated: isAuthenticated,
            ws: null,
            isWebSocketOpen: false,
            isChatOpen: false,
            chatMessages: [],
            // unreadMessageCount: {},
            timerProgress: 0,
            deferredPrompt: null,
            items: [],
            doneItems: [],
            familyID: localStorage.getItem("familyID") || null,
            updatedFamilyID: '',
            memberID: localStorage.getItem("memberID") || null,
            members: [],
            staff: [],
            maxActiveTasks: 3,
            tab: "tasks",
            showContent: {},
            filteredCategory: "All Categories",
            selectedTask: null,
            replacementButtonVisible: false,
            taskToBePinned: null,
            searchQueryTasks: '',
            searchQueryDone: '',
            dialogPosition: {
                top: 0, left: 0, width: 0, height: 0
            },
            swapDialogOpen: false,
            hasFamilyDataLoaded: false,
            hasMembersDataLoaded: false,
        };

        this.scrollableDivRef = React.createRef();
        this.bottomMenuRef = React.createRef();
        this.gridContainerRef = React.createRef();
        this.handleLoginInputChange = this.handleLoginInputChange.bind(this);
        this.handleAuth = this.handleAuth.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleNotification = this.handleNotification.bind(this);
    }

    componentDidMount() {
        this.updateGridMargin();
        this.setState({tab: this.context.currentTab})
        if (this.scrollableDivRef.current) {
            this.scrollableDivRef.current.scrollTop = parseInt(this.context.scrollValue, 10);
        }
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
        this.context.checkNetworkStatus();

        const {familyID} = this.state;
        if (familyID) {
            localStorage.setItem("familyID", familyID);
            this.performFamilyIDDependentActions(familyID);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.familyID !== this.state.familyID && this.state.familyID) {
            this.performFamilyIDDependentActions(this.state.familyID);
        }
        if (prevState.tab !== this.state.tab) {
            if (this.scrollableDivRef.current) {
                this.scrollableDivRef.current.scrollTop = 0;
            }
            this.setState({searchQueryTasks:'',searchQueryDone:''})
        }
        this.updateGridMargin();

    }


    componentWillUnmount() {
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
        if (this.scrollableDivRef.current) {
            this.context.setScrollValue(this.scrollableDivRef.current.scrollTop)
        }

        if (this.state.ws) {
            this.state.ws.close();
        }

    }

    updateGridMargin = () => {
        if (this.bottomMenuRef.current) {
            const bottomMenuHeight = this.bottomMenuRef.current.clientHeight;
            if (this.gridContainerRef.current) {
                this.gridContainerRef.current.style.marginBottom = `calc(${bottomMenuHeight}px + env(safe-area-inset-bottom) + 12px)`;
            }
        }
    }

    handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
            this.syncStateWithServer();
        }
    }

    async handleNotification(data) {
        const taskID = data.additionalData.chatID;
        const foreground = data.additionalData.foreground;

        if (taskID && !foreground) {
            const familyID = localStorage.getItem('familyID');
            await this.performFamilyIDDependentActions(familyID);
            this.handleTaskClickByID(taskID).then().catch((error) => console.error(error));
        }
    }

    initPushNotifications() {
        if (window.cordova) {
            const push = PushNotification.init({
                android: {
                    senderID: "218275581837", clearBadge: true, clearNotifications: true
                }, ios: {
                    alert: "true", badge: true, clearBadge: true, sound: "true"
                }
            });

            push.on('registration', async function (data) {
                const familyID = localStorage.getItem('familyID');
                const memberID = localStorage.getItem('memberID');
                const userAgent = navigator.userAgent;

                const registrationId = data.registrationId;
                const registrationType = data.registrationType;

                if (familyID && memberID && registrationId && registrationType) {
                    try {
                        await fetch(`${baseURL}/utils/pushNotifications.php?action=subscribe`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded',
                            },
                            body: `familyID=${familyID}&memberID=${memberID}&registrationId=${registrationId}&registrationType=${registrationType}&userAgent=${encodeURIComponent(userAgent)}`
                        });
                        console.log('Cordova registration token sent to server.');
                    } catch (error) {
                        console.error("Error in Cordova registration:", error);
                    }
                }
            });

            push.on('notification', this.handleNotification);

            push.on('error', function (e) {
                console.error('Received an error' + e);
            });
        }
    }

    syncStateWithServer = () => {
        const {familyID, memberID, appVersion} = this.state;

        fetch(`${baseURL}/utils/pushNotifications.php?action=getUnreadCounts`, {
            method: 'POST', headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }, body: `familyID=${familyID}&memberID=${memberID}&app_version=${appVersion}`,
        }).then(response => response.json())
            .then(data => {
                this.context.setUnreadCount(data.unreadMessageCount)
            })
            .catch(error => {
                console.error('Error fetching unread counts:', error);
            });
    }




    logout = () => {
        this.setState({memberID: null});
        this.setState({familyID: null});
        this.setState({noPassword: true});
        this.setState({authenticated: false});
        this.setState({authErrorMessage: ''});
        this.setState({memberEmail: ''});
        this.setState({password: ''});
        this.setState({newPassword: ''});
        this.setState({confirmPassword: ''});

        localStorage.removeItem('memberID');
        localStorage.removeItem('familyID');
        localStorage.removeItem('token');
    };

    newTask = () => {
        this.context.navigate('/newTask');
    };

    logClientAction(familyID, action) {
        fetch(`${baseURL}/utils/clientActions.php`, {
            method: "POST", body: JSON.stringify({familyID: familyID, action: action}),
        })
            .then((response) => {
                return response.text();
            })
            .then()
            .catch((error) => console.error(error));
    }

    handleSubmit(event) {
        event.preventDefault();
        const newFamilyID = this.state.updatedFamilyID.trim();
        if (newFamilyID) {
            localStorage.setItem('familyID', newFamilyID);
            this.setState({familyID: newFamilyID});
            this.performFamilyIDDependentActions(newFamilyID);
        }
    }

    renderLoginForm() {

        const buttonStyles = {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#2F98A3',
            borderRadius: 12,
            border: 0,
            color: 'white',
            fontSize: 16,
            fontFamily: 'Manrope',
            padding: '10px 8px',
            height: 40,
        };

        const {
            noPassword, memberEmail, password, newPassword, confirmPassword, authErrorMessage, hasMembersDataLoaded
        } = this.state;

        if (!hasMembersDataLoaded) {

            return (<div style={{textAlign: "center", margin: "50px auto"}}>
                <CircularProgress style={{color: '#2F98A3'}}/>
            </div>);

        }
        return (<div align='center'>
            <h3 style={{
                color: 'white', fontSize: 16, fontFamily: 'Manrope', fontWeight: 600, marginBottom: 20
            }}>
                {noPassword ? "Please create a New Password" : "Please enter your Password"}
            </h3>
            <TextField
                name="memberEmail"
                sx={{
                    input: {color: 'white'},
                    '& .MuiOutlinedInput-root': {'&.Mui-focused fieldset': {borderColor: 'transparent'}}
                }}
                style={styles.textField}
                fullWidth
                value={memberEmail}
                onChange={this.handleLoginInputChange}
                placeholder="Email Address"
                autoComplete="email"
            />

            {noPassword ? (<>
                <TextField
                    name="newPassword"
                    type="password"
                    sx={{
                        input: {color: 'white'},
                        '& .MuiOutlinedInput-root': {'&.Mui-focused fieldset': {borderColor: 'transparent'}}
                    }}
                    style={styles.textField}
                    fullWidth
                    value={newPassword}
                    onChange={this.handleLoginInputChange}
                    placeholder="New Password"
                    autoComplete="new-password"
                />
                <TextField
                    name="confirmPassword"
                    type="password"
                    sx={{
                        input: {color: 'white'},
                        '& .MuiOutlinedInput-root': {'&.Mui-focused fieldset': {borderColor: 'transparent'}}
                    }}
                    style={styles.textField}
                    fullWidth
                    value={confirmPassword}
                    onChange={this.handleLoginInputChange}
                    placeholder="Confirm New Password"
                    autoComplete="new-password"
                />
            </>) : (<TextField
                name="password"
                type="password"
                sx={{
                    input: {color: 'white'},
                    '& .MuiOutlinedInput-root': {'&.Mui-focused fieldset': {borderColor: 'transparent'}}
                }}
                style={styles.textField}
                fullWidth
                value={password}
                onChange={this.handleLoginInputChange}
                placeholder="Password"
                autoComplete="current-password"
            />)}

            <button type="submit" style={buttonStyles} onClick={this.handleAuth}>
                {noPassword ? "Set New Password" : "Login"}
            </button>

            {authErrorMessage && (<div style={{
                maxWidth: 600,
                textAlign: 'center',
                fontSize: 16,
                fontFamily: 'Manrope',
                fontWeight: '500',
                wordWrap: 'break-word',
                color: 'red',
                marginTop: '10px'
            }}>
                {authErrorMessage}
            </div>)}
        </div>);
    }

    handleLoginInputChange(event) {
        const {name, value} = event.target;
        this.setState({[name]: value});
    }

    validatePassword(password) {
        const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$");
        return regex.test(password);
    }

    async handleAuth(event) {
        event.preventDefault();

        const {familyID, memberID, memberEmail, password, newPassword, confirmPassword, noPassword} = this.state;

        if (!memberEmail) {
            this.setState({authErrorMessage: "Please enter your email address."});
            return;
        }

        if (noPassword) {
            if (newPassword !== confirmPassword) {
                this.setState({authErrorMessage: "Both passwords do not match."});
                return;
            }

            if (!this.validatePassword(newPassword)) {
                this.setState({authErrorMessage: "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character from the following: @$!%*?&, "});
                return;
            }
        }

        let payload = `username=${encodeURIComponent(memberEmail)}`;

        if (noPassword) {
            payload += `&newPassword=${encodeURIComponent(newPassword)}&familyID=${encodeURIComponent(familyID)}&memberID=${encodeURIComponent(memberID)}`;
        } else {
            payload += `&password=${encodeURIComponent(password)}`;
        }
        try {

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/utils/clientLogin.php`, {
                method: 'POST', headers: {'Content-Type': 'application/x-www-form-urlencoded'}, body: payload
            });

            const data = await response.json();
            if (data.token) {
                localStorage.setItem('token', data.token);
                this.setState({authenticated: true, authErrorMessage: ''});
                this.logClientAction(familyID, "access");
                this.performAuthenticatedActions(familyID);
                this.handleTabChange(null, "tasks")
            } else {
                this.setState({authErrorMessage: data.message});
            }

        } catch (error) {
            console.error("Error in handleAuth:", error);
            this.setState({authErrorMessage: "An error occurred. Please try again."});
        }

    };

    async performFamilyIDDependentActions(familyID) {

        this.getFamilyData(familyID);
        if (this.state.authenticated) {
            await this.performAuthenticatedActions(familyID)
        }
       
    }

    async performAuthenticatedActions(familyID) {

        this.initPushNotifications();
        this.syncStateWithServer();
        await this.context.fetchTasks('tasks').catch((error) => {
            console.error("Error fetching tasks:", error);
        });
        this.context.fetchTasks('done').catch((error) => {
            console.error("Error fetching tasks:", error);
        });
        this.context.connectWebSocket(familyID);
        this.context.setSyncStateWithServer(this.syncStateWithServer)


    }

    handleInputChange(event) {
        this.setState({updatedFamilyID: event.target.value});
    }


    async fetchMembers(familyID) {
        const requestOptions = {
            method: "POST", headers: {
                "Content-type": "application/json; charset=UTF-8",
            }, body: JSON.stringify({
                familyID: familyID, function: 'fetchMemberID',
            }),
        };

        try {
            const response = await fetch(`${baseURL}/utils/fetchFamilyData.php`, requestOptions);
            return await response.json();
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    async fetchStaff() {
        const requestOptions = {
            method: "POST", headers: {
                "Content-type": "application/json; charset=UTF-8",
            }, body: JSON.stringify({
                function: 'fetchStaff',
            }),
        };

        try {
            const response = await fetch(`${baseURL}/utils/fetchFamilyData.php`, requestOptions);
            return await response.json();
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    getFamilyData(familyID) {

        this.setState({hasFamilyDataLoaded: false})

        let assistantID = null;
        let maxActiveTasks = 3;
        let members = [];
        let staff = [];

        const requestOptions = {
            method: "POST", headers: {
                "Content-type": "application/json; charset=UTF-8",
            }, body: JSON.stringify({
                familyID: familyID, function: "fetchFamilyDataSlice",
            }),
        };


        fetch(`${baseURL}/utils/fetchFamilyData.php`, requestOptions)
            .then((response) => response.json())
            .then((data) => {

                this.setState({
                    assistantID: data["Assistant"],
                    maxActiveTasks: data["Max Active Tasks"] ? data["Max Active Tasks"] : 3,

                }, async () => {
                    members = await this.fetchMembers(familyID);
                    staff = await this.fetchStaff();
                    this.context.setStaffAndMembers(staff, members)
                    this.context.setMaxActiveTasks(this.state.maxActiveTasks)
                    this.setState({
                        members: members, staff: staff, hasFamilyDataLoaded: true

                    })

                })
            })
            .catch(error => {
                this.setState({hasFamilyDataLoaded: true})
                console.error(error);
            });

        return {assistantID, maxActiveTasks, members, staff};
    }

    fetchMemberData = (memberID) => {
        this.setState({
            hasMembersDataLoaded: false
        })

        const requestOptions = {
            method: "POST", headers: {
                "Content-type": "application/json; charset=UTF-8",
            }, body: JSON.stringify({
                memberID: memberID
            }),
        };


        fetch(`${baseURL}/utils/fetchMemberData.php`, requestOptions)
            .then((response) => response.json())
            .then(data => {
                const memberEmail = data.Email;
                this.setState({memberEmail: memberEmail, hasMembersDataLoaded: true});

            })
            .catch(error => {
                this.setState({hasMembersDataLoaded: true})
                console.error('Error fetching member data:', error)
            });
    }

    checkForPassword = async (memberID) => {

        try {

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/utils/clientLogin.php`, {
                method: 'POST',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                body: `memberIDLookup=${encodeURIComponent(memberID)}`
            });

            const data = await response.json();
            if (data.exists) {
                this.setState({noPassword: false})
            }

        } catch (error) {
            console.error("Error in handleAuth:", error);
        }

    }

    handleMemberClick = (memberId) => {
        this.setState({memberID: memberId}, async () => {
            localStorage.setItem("memberID", memberId);

            this.fetchMemberData(memberId);
            await this.checkForPassword(memberId);

        });

    }

    toggleContent = (itemId) => {
        this.setState((prevState) => ({
            showContent: {
                ...prevState.showContent, [itemId]: !prevState.showContent[itemId],
            },
        }));
    };


    handleDeleteTask = (taskId) => {
        const updatedItems = this.state.items.filter((item) => item.id !== taskId);
        this.setState({items: updatedItems});
    };


    saveItemsOrder = (items) => {
        const {familyID} = this.state;
        fetch(`${baseURL}/utils/saveOrder.php`, {
            method: "POST", body: JSON.stringify({familyID: familyID, items: items}),
        })
            .then((response) => response.text())
            .catch((error) => {
                console.error("There was an error saving the item order:", error);
            });
    };


    handleTogglePin = (taskId, isUpgrading) => {
        const {items} = this.state;
        const pinnedItemsCount = items.filter(item => item.isPinned).length;
        const apiUrl = isUpgrading ? `${baseURL}/utils/upgradeClientTask.php` : `${baseURL}/utils/downgradeClientTask.php`;

        const newItems = items.map(item => {
            if (item.id === taskId) {
                if (item.isPinned || pinnedItemsCount < this.state.maxActiveTasks) {
                    item.isPinned = !item.isPinned;
                } else {
                    console.error("Max amount of items pinned.");
                }
            }
            return item;
        });

        const requestOptions = {
            method: "POST", headers: {
                "Content-type": "application/json; charset=UTF-8"
            }, body: JSON.stringify({taskId})
        };

        fetch(apiUrl, requestOptions)
            .then(response => {
                if (response.ok) {
                    this.setState({items: newItems});
                    this.saveItemsOrder(newItems);
                } else {
                    throw new Error(`Failed to ${isUpgrading ? 'upgrade' : 'downgrade'} task`);
                }
            })
            .catch(error => {
                console.error(error);
            });
    };

    handleTaskClick = (task) => {
        if (!this.state.swapDialogOpen) {
            this.context.setChatTask(task)
            this.context.clearTaskBadges(task.id).then(() => {
                this.context.navigate('/chat');
            });
        }
    };


    handleTaskClickByID = async (taskID) => {

        if (taskID === 'General') {
            const dataObject = {
                id: 'General',
            };
            this.context.setChatTask(dataObject)
            this.context.navigate('/chat');
            return;
        }

        if (taskID.startsWith('mem_')) {
            const dataObject = {
                id: taskID,
            };
            this.context.setChatTask(dataObject)
            await this.context.setActiveGeneralChatTab('secret')
            this.context.navigate('/chat');
            return;
        }


        const {familyID} = this.state;

        const requestOptions = {
            method: "POST", headers: {
                "Content-type": "application/json; charset=UTF-8",
            }, body: JSON.stringify({
                familyID: familyID, taskID: taskID, function: "fetchClientTask"
            }),
        };

        try {
            const response = await fetch(`${baseURL}/utils/fetchClientTask.php`, requestOptions);
            const jsonResponse = await response.json();

            const data = jsonResponse.record;

            if (data && data.fields) {

                if (data.fields["Research Status"] === "Done (Published)") {
                    data.recLink = await this.context.fetchSelection(taskID);
                }

                data.index = 0;
                data.isPinned = data.fields["Urgent Task"] && data.fields["Task - Status Text"]?.[0] !== "On Hold";
                data.isHold = data.fields["Task - Status Text"]?.[0] === "On Hold";

                this.context.setChatTask(data);

                this.context.clearTaskBadges(taskID).then(() => {
                    this.context.navigate('/chat');
                });
            } else {
                console.log('No data.fields found in the response');
            }
        } catch (error) {
            console.error('Error:', error);
        }

    };

    handlePin = (taskId) => {
        this.context.handleTogglePin(taskId, true);
    };

    handleRemovePin = (taskId) => {
        this.context.handleTogglePin(taskId, false);
    };


    handleHold = (taskId) => {
        this.context.handleToggleHold(taskId, 1);
    };

    handleUnhold = (taskId) => {
        this.context.handleToggleHold(taskId, 0);
    };

    handleChildStateChange = (isErrorDialogOpen, taskIdToBePinned) => {
        this.setState({
            swapDialogOpen: isErrorDialogOpen, taskToBePinned: taskIdToBePinned
        });

        if (this.pinnedListRef) {
            const rect = this.pinnedListRef.getBoundingClientRect();
            this.setState({
                dialogPosition: {
                    top: rect.top + window.scrollY,
                    left: rect.left + window.scrollX,
                    width: rect.width,
                    height: rect.height
                }
            })
        }

    };


    getItemStyle = (draggableStyle) => ({
        minHeight: 50,
        userSelect: "none",
        padding: 8,
        margin: 12,
        background: "#2E2E2E",
        color: "#fff", ...draggableStyle,
        borderRadius: 25,
        border: '0.50px rgba(255, 255, 255, 0.10) solid'
    });


    handleCategoryFilter = (selectedCategory) => {
        this.setState({filteredCategory: selectedCategory}, () => {
            this.context.fetchTasks(this.state.tab).catch((error) => {
                console.error("Error fetching tasks:", error);
            });
        });
    }

    handleDoneTasksSearch=(searchDoneQuery)=>{
        this.setState({searchQueryDone:searchDoneQuery})
    }

    handleTaskSearch=(searchTasksQuery)=>{
        this.setState({searchQueryTasks:searchTasksQuery})
    }

    calculatePinnedCount = (items) => {
        return items.filter(item => item.isPinned).length;
    }

    

    calculateUnpinnedCount = (items) => {
        return items.filter(item => !item.isPinned).length;
    }
    
    handleTaskSelection = (task, e) => {
        this.setState({
            selectedTask: task, replacementButtonVisible: true
        });


        const taskItems = document.querySelectorAll('.task-item');
        taskItems.forEach((element) => {
            element.classList.remove('active');
        });

        const clickedElement = e.currentTarget;
        if (clickedElement.classList.contains('active')) {
            clickedElement.classList.remove('active')

        } else {
            clickedElement.classList.add('active')

        }
    };

    handleTaskReplacement = () => {

        this.handleRemovePin(this.state.selectedTask.id);
        this.handlePin(this.state.taskToBePinned);
        this.setState({
            swapDialogOpen: false, selectedTask: null, taskToBePinned: null, replacementButtonVisible: false
        });

    }
    handleSwapDialogClose = () => {
        this.setState({
            swapDialogOpen: false, selectedTask: null, taskToBePinned: null, replacementButtonVisible: false
        });

    }
    
    

    renderTaskListItems = (taskItems, priorityLabel, tasksPinnedCount) => {
        return (<div className={`${priorityLabel}`}>
            <div style={{...styles.priorityText}}>{priorityLabel}</div>
            {taskItems.map((item, index) => (<Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided) => (<Paper
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={this.getItemStyle(provided.draggableProps.style)}
                    onClick={() => this.handleTaskClick(item)}
                >
                    <TaskCard
                        index={index}
                        chatTask={item}
                        unreadMessageCount={this.context.unreadMessageCount[item.id]}
                        showControls={true}
                        isItemPinned={item.isPinned}
                        handlePin={this.handlePin}
                        handleRemovePin={this.handleRemovePin}
                        maxPinnedItems={this.state.maxActiveTasks}
                        currentPinnedCount={tasksPinnedCount}
                        onDeleteTask={this.context.handleDeleteTask}
                        isItemHold={item.isHold}
                        handleHold={this.handleHold}
                        handleUnhold={this.handleUnhold}
                        onChildStateChange={this.handleChildStateChange}
                    />
                </Paper>)}
            </Draggable>))}
        </div>);
    };
    renderTaskListSelection = (taskItems, priorityLabel, tasksPinnedCount) => {
        return (<div className={priorityLabel}>
                <div style={{...styles.priorityText}}>{priorityLabel}</div>
                {taskItems.map((item, index) => (
                    <Paper
                        key={item.id}
                        style={{...this.getItemStyle(), border: null,  opacity: item.fields['Task - Status Text'][0] === 'Info Needed' ? 0.5 : 1, }}
                        className={`task-item ${item.fields['Task - Status Text'][0] === 'Info Needed' ? 'disableHoverFocus' : ''}`}
                        onClick={item.fields['Task - Status Text'][0] === 'Info Needed' ? undefined : (e) => this.handleTaskSelection(item, e)}
                        tabIndex="0"
                    >
                        <TaskCard
                            index={index}
                            chatTask={item}
                            unreadMessageCount={this.context.unreadMessageCount[item.id]}
                            showControls={false}
                            isChat={true}
                            isItemPinned={item.isPinned}
                            onClickTask={() => this.handleTaskClick(item)}
                            handlePin={this.handlePin}
                            handleRemovePin={this.handleRemovePin}
                            maxPinnedItems={this.state.maxActiveTasks}
                            currentPinnedCount={tasksPinnedCount}
                            onDeleteTask={this.context.handleDeleteTask}
                            isItemHold={item.isHold}
                            handleHold={this.handleHold}
                            handleUnhold={this.handleUnhold}
                            onChildStateChange={this.handleChildStateChange}
                        />
                    </Paper>
                ))}
            </div>
        );
    };
    


    setMultipleRefs = (provided) => (element) => {
        provided.innerRef(element)
        this.pinnedListRef = element
    };

    renderUserView = (items) => {
        const pinnedItems = items.filter(item => item.isPinned && item.fields['Task - Status Text'][0] !== 'Info Needed');
        const unpinnedItems = items.filter(item => !item.isPinned && item.fields['Task - Status Text'][0] !== 'Info Needed');
       
        const infoNeededItems = items.filter(item => {
            return item.fields && item.fields['Task - Status Text'][0] === 'Info Needed';
        });
  
        const tasksPinnedCount = this.calculatePinnedCount(items);

        const modalTextStyle = {
            textAlign: 'center',
            color: 'white',
            fontSize: 16,
            fontFamily: 'Manrope',
            fontWeight: '500',
            wordWrap: 'break-word',
            width: '100%'
        };
        const buttonStyle = {
            alignSelf: 'stretch',
            paddingLeft: 8,
            paddingRight: 8,
            paddingTop: 10,
            paddingBottom: 10,
            background: '#2F98A3',
            borderRadius: 12,
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 8,
            display: 'inline-flex',
            cursor: 'pointer'
        };
    
        return (<div>

            {!this.context.isOnline && (<div style={{
                width: "100%",
                textAlign: 'center',
                color: 'white',
                fontSize: 16,
                fontFamily: 'Manrope',
                fontWeight: '600',
                wordWrap: 'break-word',
                backgroundColor: 'red'
            }}>
                You're offline. Failed to Fetch Tasks
            </div>)}

            <Navbar 
            onLogout={this.logout}
            onSearchChange={this.handleTaskSearch}
            tab={this.state.tab}

            />

            <Grid container style={{padding: '20px 20px 8px 20px'}}>
                <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                    <Button
                        style={{
                            width: '100%', height: '85px', borderRadius: '25px', textTransform: 'none', fontSize: '16px',  fontFamily: 'Manrope'
                        }}
                        onClick={() => this.newTask()}
                    >How can I help you?</Button>
                </Grid>
            </Grid>
            {items.length === 0 && (
                <div  style={{...styles.priorityText, textAlign: 'center', marginTop: 20 , fontSize:'20px'}}>
                    No tasks found.
                </div>
            )}
            <div className={`main-cards`}>

                <DragDropContext onDragEnd={this.context.onDragEnd}>
                <Droppable droppableId="InfoPriority">
                        {(provided) => (<div {...provided.droppableProps} ref={provided.innerRef}>
                            {infoNeededItems.length > 0 && this.renderTaskListItems(infoNeededItems, 'Info Needed', tasksPinnedCount)}
                            {provided.placeholder}
                        </div>)}
                    </Droppable>
                    <Droppable droppableId="HighPriority">
                        {(provided) => ((<React.Fragment>
                                <>
                                    <div {...provided.droppableProps} ref={this.setMultipleRefs(provided)}>
                                        {pinnedItems.length > 0 && this.renderTaskListItems(pinnedItems, 'Working On', tasksPinnedCount)}
                                        {provided.placeholder}
                                    </div>
                                    {this.state.swapDialogOpen ? 
                                        
                                        <Dialog open={this.state.swapDialogOpen} onClose={this.handleSwapDialogClose}
                                             
                                             paperprops={{
                                                 style: {
                                                     borderRadius: "25px", backgroundColor: 'white', boxShadow: "none"
                                                 }
                                             }}>

                                            <div
                                                style={{
                                                    backgroundColor: '#242426',
                                                    borderRadius: '25px',
                                                    padding: '10px'
                                                }}>

                                                <div style={modalTextStyle}><b>Choose Task To Replace</b></div>
                                                <div style={{
                                                    ...modalTextStyle, paddingTop: '5px', paddingBottom: '15px'
                                                }}>
                                                    Only {this.state.maxActiveTasks} {this.state.maxActiveTasks === 1 ? "task" : "tasks"} can
                                                    be prioritised for us to work on. Replaced task will move to
                                                    'To Do'.
                                                </div>
                                                {pinnedItems.length > 0 && this.renderTaskListSelection(pinnedItems, 'Working On', tasksPinnedCount)}
                                                {provided.placeholder}
                                                <div style={{display: 'flex', justifyContent: 'space-around',}}>
                                                    <div style={{
                                                        ...buttonStyle,
                                                        marginRight: '10px',
                                                        backgroundColor: 'grey',
                                                        flex: 1,
                                                    }}>
                                                        <div onClick={this.handleSwapDialogClose}
                                                             style={modalTextStyle}>
                                                            Cancel
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        ...buttonStyle, backgroundColor: 'rgb(47, 152, 163)', flex: 1,
                                                    }}>
                                                        <div
                                                            onClick={this.state.replacementButtonVisible ? this.handleTaskReplacement : null}
                                                            style={this.state.replacementButtonVisible ? modalTextStyle : {
                                                                ...modalTextStyle,
                                                                cursor: 'not-allowed',
                                                                pointerEvents: 'none',
                                                                opacity: '50%'
                                                            }}>
                                                            OK
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Dialog>
                                     : null}
                                </>
                            </React.Fragment>

                        ))}


                    </Droppable>
                    <Droppable droppableId="NormalPriority">
                        {(provided) => (<div {...provided.droppableProps} ref={provided.innerRef}>
                            {unpinnedItems.length > 0 && this.renderTaskListItems(unpinnedItems, 'To Do', tasksPinnedCount)}
                            {provided.placeholder}
                        </div>)}
                    </Droppable>
                </DragDropContext>
            </div>
        </div>);
    };



    renderTaskList() {
        const { searchQueryTasks } = this.state;

        const filteredTasks = this.context.items.filter(item => item.fields["Title"].toLowerCase().includes(searchQueryTasks.toLowerCase()));
        return this.renderUserView(filteredTasks);
    }

    renderCompletedTasks() {
        const { filteredCategory, searchQueryDone } = this.state;
        const filteredItems = this.context.doneItems
            .filter(item =>
                filteredCategory === "All Categories" || item.fields["Main Category"] === filteredCategory)
            .filter(item =>
                !searchQueryDone || item.fields["Title"].toLowerCase().includes(searchQueryDone.toLowerCase()));

        return (<div>

            <Navbar
                onLogout={this.logout}
                onSearchChange={this.handleTaskSearch}
                onDoneSearchChange={this.handleDoneTasksSearch}
                tab={this.state.tab}

            />

            <CategoryAlertDialog onCategoryChange={this.handleCategoryFilter} onSearchChange={this.handleDoneTasksSearch}/>
            {filteredItems.map((item) => (<Paper
                key={item.id}
                style={this.getItemStyle(false)}
                onClick={() => this.handleTaskClick(item)}
            >
                <TaskCard
                    showControls={true}
                    index={0}
                    chatTask={item}
                    unreadMessageCount={this.context.unreadMessageCount[item.id]}
                    />


                {item.fields["receipt"] && (<PaymentDetailsSection
                    item={item}
                    receipt={item.fields["receipt"]}
                    isVisible={this.state.showContent[item.id]}
                    onToggle={() => this.toggleContent(item.id)}
                    itemId={item.id}
                />)}
            </Paper>))}
        </div>);
    }


    handleTabChange = (event, newValue) => {

        // this.setState(prevState => ({
        //     unreadMessageCount: {
        //         ...prevState.unreadMessageCount, 0: 0
        //     }
        // }));

        this.setState({tab: newValue});
        this.context.fetchTasks(this.state.tab).catch((error) => {
            console.error("Error fetching tasks:", error);
        });

    }
    renderChat() {
        const dataObject = {
            id: 'General',
        };
        this.context.setChatTask(dataObject)
        this.context.navigate('/chat');

}


    render() {
        const {
            familyID, memberID, authenticated, members, tab
        } = this.state;


        if (!familyID) {
            return (<div style={styles.container}>
                <div style={styles.formSection}>
                    <Typography component="div" style={{padding: 12, color: "white", textAlign: "center" }}>
                        Welcome to BlckBx. Please login with your Family ID
                    </Typography>
                    <form style={styles.form} onSubmit={this.handleSubmit}>
                        <div style={{
                            margin: '0 auto',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            maxWidth: '850px'
                        }}>
                            <TextField
                                sx={{
                                    input: { color: 'white' }, '& .MuiOutlinedInput-root': {
                                        backgroundColor: '#323232',
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'transparent',
                                        },
                                    },
                                }}
                                style={{ ...styles.textField, flex: 1, marginRight: '8px' }}
                                onChange={this.handleInputChange}
                                fullWidth
                            />
                            <Button style={styles.submitButton} type="submit">
                                <LoginIcon style={{ color: "white" }} />
                            </Button>
                        </div>
                    </form>
                </div>
                <div style={styles.exploreLinkSection}>
                    {/*<Typography component="div" style={{padding: 12, color: "white"}}>*/}
                    {/*    Or browse our curated lists as a guest*/}
                    {/*</Typography>*/}
                    {/*<div style={styles.tilesContainer}>*/}
                    {/*    <div style={styles.tile}>Travel</div>*/}
                    {/*    <div style={styles.tile}>Gifts</div>*/}
                    {/*    <div style={styles.tile}>Activities</div>*/}
                    {/*    <div style={styles.tile}>Dining</div>*/}
                    {/*</div>*/}
                </div>
                <div style={styles.footerSection}>
                    <Typography component="div" style={{padding: 12, color: "white"}}>
                        Find out more about <a style={styles.link} href="https://blckbx.co.uk">BlckBx here</a>
                    </Typography>

                </div>
            </div>);
        } else {
            if (!memberID) {

                const {hasFamilyDataLoaded} = this.state

                const containerStyles = {
                    width: "100%",
                    maxHeight: "fit-content",
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 12,
                    display: 'flex',
                    position: 'fixed',
                    bottom: 24,
                    left: 0,
                    right: 0,
                };

                const textStyles = {
                    textAlign: 'center', color: 'white', fontSize: 16, fontFamily: 'Manrope', wordWrap: 'break-word'
                };

                const buttonContainerStyles = {
                    width: "100%",
                    maxHeight: "fit-content",
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 8,
                    display: 'flex'
                };

                const buttonStyles = {
                    width: "80%",
                    maxWidth: 400,
                    height: 40,
                    padding: '10px 8px',
                    background: '#2F98A3',
                    borderRadius: 12,
                    border: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white',
                    fontSize: 16,
                    fontFamily: 'Manrope',
                    wordWrap: 'break-word'
                };

                if (!hasFamilyDataLoaded) {

                    return (<div style={{textAlign: "center", margin: "50px auto"}}>
                        <CircularProgress style={{color: '#2F98A3'}}/>
                    </div>);

                }

                return (<>
                    <div style={{
                        color: 'white',
                        padding: 20,
                        fontSize: 16,
                        fontFamily: 'Manrope',
                        wordWrap: 'break-word',
                        marginBottom: 20
                    }} onClick={this.logout}>Go Back
                    </div>
                    <div style={containerStyles}>
                        {members.length === 1 ? (<>

                            <div style={{...textStyles, alignSelf: 'center', height: 20, fontWeight: '600'}}>
                                Welcome, {members[0].Name}
                            </div>

                            <div style={buttonContainerStyles}>
                                <button
                                    key={members[0].ID}
                                    style={buttonStyles}
                                    onClick={() => this.handleMemberClick(members[0].ID)}
                                >
                                    Enter
                                </button>
                            </div>
                        </>) : (<>
                            <div style={{...textStyles, alignSelf: 'center', height: 20, fontWeight: '600'}}>
                                Welcome, please select your name
                            </div>
                            <div style={buttonContainerStyles}>
                                {members.map((member) => (<button
                                    key={member.ID}
                                    style={buttonStyles}
                                    onClick={() => this.handleMemberClick(member.ID)}
                                >
                                    {member.Name}
                                </button>))}
                            </div>
                        </>)}
                    </div>
                </>)


            } else if (!authenticated) {

                return (<>
                    <div>
                        <div style={{
                            color: 'white',
                            padding: 20,
                            fontSize: 16,
                            fontFamily: 'Manrope',
                            cursor: 'pointer',
                            marginBottom: 20
                        }} onClick={this.logout}>
                            Go Back
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100vh'
                        }}>

                            <form onSubmit={this.handleAuth}>
                                {this.renderLoginForm()}
                            </form>
                        </div>
                    </div>
                </>);
            } else {

                if (!this.context.hasTasksLoaded) {

                    return (<div style={{textAlign: "center", margin: "50px auto"}}>
                        <CircularProgress style={{color: '#2F98A3'}}/>
                    </div>);

                }

                return (<div>
                    <ThemeProvider theme={MUIStyles}>
                        <Box ref={this.scrollableDivRef} sx={{overflow: 'auto', height: '100vh'}} id="app-container">
                            <Box ref={this.bottomMenuRef} sx={{
                                position: 'fixed',
                                bottom: 0,
                                left: 0,
                                right: 0,
                                py: 1,
                                bgcolor: '#121214',
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                zIndex: 999996
                            }}>
                                <MyTabs
                                    handleTabChange={this.handleTabChange}
                                    activeTab={this.state.tab}
                                    unreadMessageCount={this.context.unreadMessageCount}
                                />
                            </Box>
                            <Grid container spacing={2} ref={this.gridContainerRef}>
                                <Grid item xs={12}>
                                    {tab === "tasks" && this.renderTaskList()}
                                    {tab === "done" && this.renderCompletedTasks()}
                                    {tab === "chat" && this.renderChat()}
                                </Grid>
                            </Grid>
                        </Box>
                    </ThemeProvider>
                </div>);
            }
        }
    }
}


const createBanner = () => {
    const banner = document.createElement('div');
    banner.style.position = "fixed";
    banner.style.top = "0";
    banner.style.left = "0";
    banner.style.width = "100%";
    banner.style.zIndex = "999998";
    return banner;
};

const adjustBodyMargin = (bannerHeight) => {
    document.body.style.marginTop = bannerHeight + 'px';
};

const createToolbar = () => {
    const toolbar = document.createElement('div');
    toolbar.style.display = "flex";
    toolbar.style.justifyContent = "space-between";
    toolbar.style.alignItems = "center";
    toolbar.style.backgroundColor = "#2F98A3";
    toolbar.style.color = "#ffffff";
    toolbar.style.padding = "10px";
    return toolbar;
};

const createText = (text) => {
    const span = document.createElement('span');
    span.innerText = text;
    span.style.flexGrow = "1";
    span.style.color = "#ffffff";
    span.style.fontFamily = "Manrope";
    span.style.fontWeight = "500";
    span.style.fontSize = "12px";
    span.style.paddingBottom = "12px";
    span.style.paddingTop = "12px";
    return span;
};

const createButton = (text) => {
    const button = document.createElement('button');
    button.innerText = text;
    button.style.backgroundColor = "#2F98A3";
    button.style.color = "#fff";
    button.style.border = "none";
    button.style.padding = "10px 20px";
    button.style.cursor = "pointer";
    button.style.fontFamily = "Manrope";
    button.style.fontSize = "12px";
    return button;
};

const subscribeToNotifications = async () => {
    const familyID = localStorage.getItem('familyID');
    const memberID = localStorage.getItem('memberID');
    const userAgent = navigator.userAgent;
    try {

        const readyRegistration = await navigator.serviceWorker.ready;
        const subscription = await readyRegistration.pushManager.getSubscription();
        if (!subscription) {
            const newSubscription = await readyRegistration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: 'BMDwOEUNSGgdzEzD_SMF0YcQ_8trBFArJShGxFo-mNhtMiOE-PIV1zXQD7S_4219deVwghAFLYcHnnIlCsCEEQc'
            });

            await fetch(`${baseURL}/utils/pushNotifications.php?action=subscribe`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `familyID=${familyID}&memberID=${memberID}&subscription=${JSON.stringify(newSubscription)}&userAgent=${encodeURIComponent(userAgent)}`,
            });
        }
    } catch (error) {
        console.error("Error in subscribeToNotifications:", error);

    }

};

const registerServiceWorker = async () => {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
        try {

            const registration = await navigator.serviceWorker.register('/service-worker.js?v=1_5_0');
            let refreshing = false;

            // Listen for updates to the service worker.
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (refreshing) return;
                window.location.reload();
                refreshing = true;
            });

            // Check if the new service worker is installed.
            if (registration.waiting) {
                registration.waiting.postMessage({type: 'SKIP_WAITING'});
            }

            const newWorker = registration.installing;
            if (newWorker) {
                newWorker.addEventListener('statechange', () => {
                    if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
                        // A new service worker is available, post a message to it.
                        newWorker.postMessage({type: 'SKIP_WAITING'});
                    }
                });
            }

            const familyID = localStorage.getItem('familyID');
            const memberID = localStorage.getItem('memberID');
            const existingPermission = localStorage.getItem('notification-permission');

            if (familyID && memberID) {
                if (existingPermission === 'granted') {
                    await subscribeToNotifications();
                } else {
                    const enableButton = createButton("Allow");
                    const toolbar = createToolbar();
                    const text = createText("Enable BlckBx App Notifications?");
                    const banner = createBanner();

                    toolbar.appendChild(text);
                    toolbar.appendChild(enableButton);
                    banner.appendChild(toolbar);
                    document.body.appendChild(banner);
                    adjustBodyMargin(banner.offsetHeight);


                    enableButton.addEventListener("click", async () => {
                        banner.remove();
                        adjustBodyMargin(0);

                        const permission = await Notification.requestPermission();
                        if (permission === 'granted') {
                            localStorage.setItem('notification-permission', 'granted');
                            await subscribeToNotifications();
                        }
                    });
                }
            }
        } catch (error) {
            console.error('Something went wrong during registration:', error);
        }
    } else {
        console.error("This browser does not support push notifications.");
    }
};

const main = async () => {
    try {
        await registerServiceWorker();
    } catch (error) {
        console.error('Service worker registration failed:', error);
    }
};

document.addEventListener('deviceready', onDeviceReady, false);

function onDeviceReady() {
    if (window.cordova) {
        setupUniversalLinks();
        startReactApp();
    }
}

function setupUniversalLinks() {
    universalLinks.subscribe('openChatRoute', onDeepLink.bind(this));
}

function onDeepLink(e) {

    const {url, path, params} = e;
    console.log("Deep link URL:", url);
    console.log("Path:", path);
    console.log("Parameters:", JSON.stringify(params, null, 2));

}

function startReactApp() {
    const container = document.getElementById("root");
    const root = createRoot(container);
    root.render(<RoutingApp/>);
}

if (!window.cordova) {
    startReactApp();

    main()
        .then(() => {
        })
        .catch((error) => {
            console.error('Main function failed:', error);
        });
}