import React from 'react';
import {Button, Dialog, DialogTitle, DialogActions, Box,} from '@mui/material';
import {AppContext} from './AppContext';

class TaskAddedSuccessDialog extends React.Component {
    static contextType = AppContext;

    handleBack = () => {
        this.context.navigate('/#');
    };

    render() {
        const {open, onClose} = this.props;


        return (<Dialog
            open={open}
            onClose={onClose}
            sx={{'& .MuiPaper-root': {backgroundColor: '#242426', color: '#fff', minWidth: '300px'}}}
        >
            <DialogTitle sx={{color: '#fff', fontSize: '18px', textAlign: 'center'}}>
            {this.props.isSuccess ? "We've got it!" : "Error!! Please try again later"}
            </DialogTitle>
            <DialogActions>
                <Box width="100%" display="flex" flexDirection="row" gap={2}>

                    <Button
                        onClick={this.handleBack}
                        variant="contained"
                        fullWidth
                        sx={{
                            backgroundColor: '#48484D', '&:hover': {
                                backgroundColor: '#48484D',
                            }, textTransform: 'none'
                        }}
                    >
                        Go Back
                    </Button>

                    <Button
                        onClick={onClose}
                        variant="contained"
                        fullWidth
                        sx={{
                            backgroundColor: '#2F98A3', '&:hover': {
                                backgroundColor: '#2F98A3',
                            }, textTransform: 'none'
                        }}
                    >
                        Add New
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>);
    }
}

export default TaskAddedSuccessDialog;
