import React from 'react';
import {Typography, Button, Grid} from "@mui/material";
import QRCode from 'qrcode.react';
import {Link} from 'react-router-dom';

const baseURL = process.env.REACT_APP_API_BASE_URL;

class Referral extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            referralLink: '', memberName: '',
        };
    }

    componentDidMount() {
        this.fetchMemberName();
    }

    fetchMemberName = () => {
        const memberID = localStorage.getItem("memberID");
        const familyID = localStorage.getItem("familyID");

        const requestOptions = {
            method: "POST", headers: {
                "Content-type": "application/json; charset=UTF-8",
            }, body: JSON.stringify({
                memberID: memberID
            }),
        };

        fetch(`${baseURL}/utils/fetchMemberData.php`, requestOptions)
            .then((response) => response.json())
            .then(data => {
                const memberName = data.First;
                this.setState({
                    memberName: memberName,
                    referralLink: `https://form.typeform.com/to/Qr930SAI#member_first_name=${encodeURIComponent(memberName)}&ref_family_id=${encodeURIComponent(familyID)}`,
                });
            })
            .catch(error => console.error('Error fetching member name:', error));
    }


    copyToClipboard = () => {
        navigator.clipboard.writeText(this.state.referralLink)
            .then(() => alert('Referral link copied to clipboard!'))
            .catch(err => console.error('Could not copy text: ', err));
    }

    render() {
        const {referralLink} = this.state;

        return (<div>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={1} sm={1} style={{padding: 12, display: 'flex', justifyContent: 'center'}}>
                    <Link to={`/#`} style={{color: 'white'}}>
                        <img alt="Return to Main Menu" src="./app-icons/chevron-left.svg"/>
                    </Link>
                </Grid>

                <Grid item xs={10} sm={10} style={{padding: 12, display: 'flex', justifyContent: 'center'}}>
                    <Typography variant="h6" component="div"
                                style={{textAlign: 'center', fontFamily: 'Manrope', fontWeight: '600', color: 'white'}}>
                        Refer a Friend to BlckBx
                    </Typography>
                </Grid>

                <Grid item xs={1} sm={1}></Grid>
            </Grid>
            <div style={{
                display: 'flex', minHeight: '100vh', justifyContent: 'center', alignItems: 'center', padding: '20px'
            }}>
                <Grid container spacing={2} alignItems="center" justifyContent="center"
                      style={{textAlign: "center"}}>
                    <Grid item xs={12} md={8} lg={6} style={{
                        display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
                    }}>
                        <Typography variant="h7" component="div" style={{
                            color: 'white', fontFamily: 'Manrope', fontWeight: '400', marginBottom: 20
                        }}>
                            Want to let your friends, family or colleagues try BlckBx as a free gift from you?
                            <br/> <br/>
                            We will do one task for them for free to experience BlckBx.
                            <br/> <br/>
                            Please ask them to scan your personal QR code below or copy and text them the link.
                        </Typography>
                        <QRCode value={referralLink} size={128} level={"H"}/>
                        <div style={{color: 'white', margin: '10px 0', wordBreak: 'break-all'}}>{referralLink}</div>
                        <Button onClick={this.copyToClipboard} variant="contained"
                                style={{marginTop: 10, backgroundColor: 'white', color: 'black'}}>
                            Copy Link
                        </Button>
                    </Grid>
                </Grid>
            </div>

        </div>);
    }
}

export default Referral;
