import React, {Component} from 'react';
import AlertDialog from "./AlertDialog"
import {Box, CircularProgress, Fab, IconButton, Typography} from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import DoneIcon from '@mui/icons-material/Done';

class TaskCard extends Component {

    constructor(props) {
        super(props);
        this.controlsRef = React.createRef();
        this.circularProgressRef = React.createRef();
        this.state = {
            titleMaxWidth: '250px',
        };
    }

    componentDidMount() {
        this.updateTitleWidth();
    }

    updateTitleWidth = () => {
        if (this.controlsRef.current && this.circularProgressRef.current) {
            const controlsWidth = this.controlsRef.current.offsetWidth;
            const progressWidth = this.circularProgressRef.current.offsetWidth;
            const newWidth = window.innerWidth - controlsWidth - progressWidth - 60;
            this.setState({ titleMaxWidth: `${newWidth}px` });
        }
    };

    getRelevantIcon(chatTask) { //todo use the categories from a singular endpoint (see Completed Tasks)
        if (this.isPausedItem(chatTask.fields["Task - Status Text"])) {
            return <img height={28} width={28} src="./task-icons/pause.svg" alt="Icon"/>;
        } else if (this.isHoldItem()) {
            return <img height={28} width={28} src="./task-icons/hold.svg" alt="Icon"/>;
        } else {
            const iconBasePath = "./task-icons/";
            let iconName, altText;

            switch (chatTask.fields["Main Category"]) {
                case "Activities":
                    iconName = "activities.svg";
                    altText = "Activities icon";
                    break;
                case "Admin Finance and Legal":
                    iconName = "admin.svg";
                    altText = "Admin Finance and Legal icon";
                    break;
                case "Birthdays and Events":
                    iconName = "birthday.svg";
                    altText = "Birthdays and Events icon";
                    break;
                case "Dining":
                    iconName = "dining.svg";
                    altText = "Dining icon";
                    break;
                case "Education":
                    iconName = "education.svg";
                    altText = "Education icon";
                    break;
                case "Gifts and Shopping":
                    iconName = "gifts.svg";
                    altText = "Gifts and Shopping icon";
                    break;
                case "Health and Wellbeing":
                    iconName = "health.svg";
                    altText = "Health and Wellbeing icon";
                    break;
                case "Holidays and Travel":
                    iconName = "holidays.svg";
                    altText = "Holidays and Travel icon";
                    break;
                case "Home and Garden":
                    iconName = "home.svg";
                    altText = "Home and Garden icon";
                    break;
                case "Family":
                    iconName = "family.svg";
                    altText = "Family icon";
                    break;
                case "Pets":
                    iconName = "pets.svg";
                    altText = "Pets icon";
                    break;
                case "Real Estate":
                    iconName = "real-estate.svg";
                    altText = "Real Estate icon";
                    break;
                case "Vehicles":
                    iconName = "vehicles.svg";
                    altText = "Vehicles icon";
                    break;
                default:
                    iconName = "new-task-icon.png";
                    altText = "Default task icon";
                    break;
            }

            return <img src={`${iconBasePath}${iconName}`} alt={altText} height="22" width="22" />;
        }
    }


    isCompletedItem = (item) => {
        return (item.includes("Done") || item.includes("Feedback/Payment"));
    };

    isPausedItem = (item) => {
        return (item.includes("Info Needed"));
    };

    isHoldItem = () => {
        return (this.props.isItemHold);
    };

    unreadMessage() {
        return this.props.unreadMessageCount || false;
    }

    handleInTitleUrl = (text) => {
        const linkStyles = {
            wordBreak: 'break-all',
        };
    
        const urlRegex = /(https?:\/\/[^\s]+)/g;
    
        let parts = [];
        let subParts = text.split(urlRegex);
        subParts.forEach((subPart, subIndex) => {
            if (urlRegex.test(subPart)) {
                parts.push(<a key={subIndex} href={subPart} style={{...linkStyles, color: '#ffff'}} target="_blank" rel="noopener noreferrer">{subPart}</a>);
            } else {
                parts.push(<span key={subIndex}>{subPart}</span>);
            }
        });
    
        return parts;
    };
    

    render() {

        const {chatTask, showControls, isChat} = this.props;
        const { titleMaxWidth } = this.state;

        const isChatWindow= isChat? true : false;

        const chatTaskCardStyle ={
            fontSize: 16,
            color: "#fff",
            fontWeight: '500',
            fontFamily: 'Manrope',
            wordWrap: 'break-word',   
        }

        const listTaskCardStyle ={
            fontSize: 16,
            color: "#fff",
            fontWeight: '500',
            fontFamily: 'Manrope',
            wordWrap: 'break-word',
            overflow: 'hidden',  
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: titleMaxWidth
        }

        return (<Typography component="div" style={{
            display: "flex", alignItems: "center", justifyContent: "space-between", width: '100%', position: 'relative',
        }}>

            <div id="card-details"
                 style={{display: "flex", alignItems: "center", flex: 1}}>

                <div ref={this.circularProgressRef} style={{marginRight: 12, display: 'flex', alignItems: 'flex-end',}}>
                    {chatTask.fields["In Progress Percentage Rollup (from Task - Movement Log)"] || this.isCompletedItem(chatTask.fields["Task - Status Text"]) ?
                        <Box size={64}
                             sx={{
                                 position: "relative", color: "#FFFFFF33",
                             }}
                        >
                            <Fab
                                sx={{
                                    height: 48, width: 48, backgroundColor: "transparent", "&:hover": {
                                        backgroundColor: "transparent",
                                    },
                                }}
                            >
                                {this.getRelevantIcon(chatTask)}
                            </Fab>
                            <CircularProgress
                                variant="determinate"
                                size={48}
                                value={100}
                                sx={{
                                    color: this.isPausedItem(chatTask.fields["Task - Status Text"]) ? "#121212" : "#FFFFFF33",
                                    position: "absolute",
                                    left: 0,
                                    zIndex: 1,
                                }}
                            />
                            <CircularProgress
                                variant="determinate"
                                size={48}
                                value={this.isCompletedItem(chatTask.fields["Task - Status Text"]) ? 100 : Number(chatTask.fields["In Progress Percentage Rollup (from Task - Movement Log)"]
                                    ?.split(",")
                                    .map((percent) => (parseFloat(percent.trim()) * 100).toFixed(0))
                                    .filter((percent) => !isNaN(percent))
                                    .pop() || 0)}
                                sx={{
                                    color: this.isPausedItem(chatTask.fields["Task - Status Text"]) ? "#FF003D" : this.isHoldItem() ? "#7A7A7A" : "#2F98A3",
                                    position: "absolute",
                                    left: 0,
                                    zIndex: 1,
                                }}
                            />
                        </Box> : <Box size={64}
                                      sx={{
                                          position: "relative", color: "#FFFFFF33",
                                      }}
                        >

                            <Fab
                                sx={{
                                    height: 48, width: 48, backgroundColor: "transparent", "&:hover": {
                                        backgroundColor: "transparent",
                                    },
                                }}
                            >

                                {this.getRelevantIcon(chatTask)}

                            </Fab>
                            <CircularProgress
                                variant="determinate"
                                size={48}
                                value={100}
                                sx={{
                                    color: "#FFFFFF33", position: "absolute", left: 0, zIndex: 1,
                                }}
                            />

                        </Box>}
                </div>

                <div>
                    <div style={isChatWindow? chatTaskCardStyle : listTaskCardStyle }>
                        {this.handleInTitleUrl(chatTask.fields["Title"])}
                    </div>
                </div>
            </div>
            {showControls && (
                <div id="card-controls"  ref={this.controlsRef} style={{ display: 'flex', flexDirection:'row-reverse', alignItems: 'center', zIndex: 1000 }}>
                    {this.isCompletedItem(chatTask.fields["Task - Status Text"]) ? (
                        <DoneIcon style={{color: '#2F98A3'}}/>
                    ) : (
                        <IconButton onClick={(event) => event.stopPropagation()}>
                            <AlertDialog
                                itemId={chatTask.id}
                                handlePin={this.props.handlePin}
                                handleRemovePin={this.props.handleRemovePin}
                                isPinned={this.props.isItemPinned}
                                isHold={this.props.isItemHold}
                                isInfoNeeded={chatTask.fields['Task - Status Text'][0] === 'Info Needed'}
                                handleHold={this.props.handleHold}
                                handleUnhold={this.props.handleUnhold}
                                maxPinnedItems={this.props.maxPinnedItems}
                                currentPinnedCount={this.props.currentPinnedCount}
                                onDeleteTask={this.props.onDeleteTask}
                                onChildStateChange={this.props.onChildStateChange}
                            />
                        </IconButton>
                    )}

                    {this.unreadMessage() > 0 && (<div style={{
                        width: 20,
                        height: 20,
                        background:  '#FF003D',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <div style={{
                            color: '#fff',
                            fontSize: 12,
                            fontFamily: 'Manrope',
                            fontWeight: '500',
                            lineHeight: '20px',
                            wordWrap: 'break-word'
                        }}>
                            {this.unreadMessage()}
                        </div>
                    </div>)}

                    {chatTask.recLink && (
                        <StarIcon />
                    )}

                    {chatTask.fields["txnAmount"] && (
                        <div style={{
                            textAlign: 'center',
                            color: 'rgba(255, 255, 255, 0.50)',
                            fontSize: 14,
                            padding: '12px',
                            fontFamily: 'Manrope',
                            fontWeight: '500'
                        }}>
                            £{chatTask.fields["txnAmount"]}
                        </div>
                    )}
                </div>
            )}


        </Typography>);
    }
}

export default TaskCard;