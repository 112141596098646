import {
    createTheme
} from "@mui/material";

const MUIStyles = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: "#2F98A3", color: "#fff", "&:hover": {
                        backgroundColor: "#2F98A3",
                    },
                },
            },
        }, MuiFab: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                },
            },
        }, MuiTabs: {
            styleOverrides: {
                root: {
                    width: "100%",
                    display: 'flex',
                    justifyContent: 'space-around',
                    background: '#1D5F66',
                    boxShadow: '0px -4px 8px rgba(0, 0, 0, 0.50)',
                    borderRadius: 24,
                    border: '0.50px rgba(255, 255, 255, 0.10) solid',
                    alignItems: 'center',
                }, indicator: {
                    backgroundColor: "var(--tabs-indicator-color, #2F98A3)", height: "0px"
                },
            },
        }, MuiTab: {
            styleOverrides: {
                root: {
                    flexGrow: 1,
                    flex: '1 1 auto',
                    flexShrink: 1,
                    alignSelf: 'stretch',
                    padding: '6px',
                    borderRadius: 16,
                    overflow: 'hidden',
                    "&.Mui-selected": {
                        backgroundColor: "#2F98A3", color: "#FFFFFF",
                    },
                    "&:not(.Mui-selected)": {
                        backgroundColor: "#1D5F66", color: "rgba(255, 255, 255, 0.50)",
                    },
                },
            },
        }, MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: "#121214",
                },
            },
        }, MuiTextField: {
            styleOverrides: {
                root: {
                    "& .MuiInputLabel-shrink": {
                        color: "#888888",
                    },
                },
            },
        }, MuiInputBase: {
            styleOverrides: {
                input: {
                    color: "#888888",
                },
            },
        }, MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                    }, "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                    },
                },
            },
        },
    },
});

export default MUIStyles;