import React, {Component} from 'react'
import {Button} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'

class MessageEditDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.isMessageEditDialogOpen || false, confirmDeleteOpen: false,
        };
    }

    handleConfirmDeleteClick = () => {
        this.setState({confirmDeleteOpen: true});
    }

    handleConfirmDeleteClose = () => {
        this.setState({confirmDeleteOpen: false});
    }

    handleClose = () => {
        this.setState({open: false});
        this.props.onClose();

    }

    onDeleteMessage = (messageId) => {
        this.setState({
            confirmDeleteOpen: false, open: false,
        });
        this.props.handleDeleteMessage(messageId);
    };

    handleReplyClick = () => {
        this.props.handleReplyMessage(this.props.selectedMessage);
    };
    handleEditClick = () => {
        this.props.handleEditMessage(this.props.selectedMessage);
    };

    render() {

        const {open, confirmDeleteOpen} = this.state;
        const {selectedMessage, isChatClosed} = this.props;
        const messageId = selectedMessage.ID
        const isEditableandDeletable =  !selectedMessage.user_id

        const buttonsConfig = isChatClosed ? [
            {
                label: 'Cancel',
                onClick: this.handleClose,
                style: { backgroundColor: '#48484D' },
                show: true
            }
        ] : [
            {
                label: 'Delete',
                onClick: this.handleConfirmDeleteClick,
                style: { backgroundColor: '#FF003D' },
                show: isEditableandDeletable 
            },
            {
                label: 'Edit',
                onClick: this.handleEditClick,
                style: { backgroundColor: '#48484D' },
                show: isEditableandDeletable 
            },
            {
                label: 'Reply',
                onClick: this.handleReplyClick,
                style: { backgroundColor: '#48484D' },
                show: true 
            },
            {
                label: 'Cancel',
                onClick: this.handleClose,
                style: { backgroundColor: '#48484D' },
                show: true
            }
        ];
        


        const styles = {
            dialogTitle: {
                textAlign: 'center',
                color: 'white',
                fontSize: 16,
                fontFamily: 'Manrope',
                fontWeight: '500',
                wordWrap: 'break-word',
                marginBottom: 12,
                padding: 0,
            }, button: {
                display: 'flex',
                color: '#ffffff',
                backgroundColor: '#48484D',
                fontSize: 16,
                textTransform: 'none',
                borderRadius: 12,
                padding: '8px 10px',
                width: '100%',
            }, dialogContainer: {
                display: 'flex',
                alignItems: 'center',
                borderRadius: 10,
                padding: 10,
                paddingRight: 100,
                marginBottom: 10,
                fontFamily: 'Manrope',
                cursor: 'pointer',
            }, dialogIcon: {
                color: '#ffffff', marginRight: 8, width: 20, height: 20,
            },
        }


        return (<>
            <Dialog  
                open={open} onClose={this.handleClose}
                aria-labelledby='dialog-title' aria-describedby='dialog-description'
                PaperProps={{
                    style: {
                        display: 'flex',
                        justifyContent: 'center',
                        borderRadius: '20px',
                        backgroundColor: '#2E2E2E',
                        boxShadow: 'none',
                        minWidth: '300px'
                    }
                }}>
                <div style={{padding: '12px'}}>
                    <DialogTitle id='dialog-title' style={styles.dialogTitle}>{isChatClosed ? "Chat is closed" : "Options"}</DialogTitle>
                    <DialogContent sx={{padding: '0', gap: 12}}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div style={{display: 'flex', width: '100%', gap: 8, marginBottom: 8, flexDirection:'column'}}>
                              
                                {buttonsConfig.map((button, index) => button.show && (
                                    <Button
                                        key={index}
                                        onClick={button.onClick}
                                        style={{ ...styles.button, ...button.style }}
                                    >
                                        {button.label}
                                    </Button>
                                ))}
                                
                            </div>

                        </div>
                    </DialogContent>
                </div>
            </Dialog>
            <Dialog
                sx={{zIndex: 9999999999}}
                PaperProps={{
                    style: {
                        display: 'flex',
                        justifyContent: 'center',
                        borderRadius: '20px',
                        backgroundColor: '#2E2E2E',
                        boxShadow: 'none',
                    }
                }}
                open={confirmDeleteOpen} onClose={this.handleConfirmDeleteClose}
                aria-labelledby='second-dialog-title' aria-describedby='second-dialog-description'>
                <DialogTitle id='second-dialog-title' style={{...styles.dialogTitle, marginTop: 12}}>Delete
                    message?</DialogTitle>
                <DialogContent sx={{padding: '0', gap: 12}}>
                    <div style={{display: 'flex', gap: 8, padding: '0 12px', marginBottom: 12}}>
                        <Button onClick={this.handleConfirmDeleteClose}
                                style={{...styles.button, backgroundColor: '#48484D'}}>Cancel</Button>
                        <Button onClick={() => this.onDeleteMessage(messageId)}
                                style={{...styles.button, backgroundColor: '#FF003D'}}>Delete</Button>
                    </div>
                </DialogContent>

            </Dialog>
        </>);
    }
}

export default MessageEditDialog