import React from 'react';
import {
    Typography, Paper, List, ListItem, ListItemText, Divider, Badge, Grid, IconButton
} from "@mui/material";
import {AppContext} from './AppContext';
import CloseIcon from '@mui/icons-material/Close';
import {Link} from 'react-router-dom';

const MAKE_HIDDEN = 1;
const MARK_READ = 0;

class Notifications extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            notificationData: [], gridHeight: 0,
        };
        this.gridRef = React.createRef();
    }

    componentDidMount() {
        this.updateGridHeight();
        window.addEventListener('resize', this.updateGridHeight);
    }

    componentDidUpdate() {
        this.updateGridHeight();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateGridHeight);
    }


    updateGridHeight = () => {
        if (this.gridRef.current) {
            const height = this.gridRef.current.clientHeight;
            if (height !== this.state.gridHeight) {
                this.setState({gridHeight: height});
            }
        }
    };
    clearNotificationFromServer = (id, clear) => {


        if (this.context.isWebSocketOpen) {
            const socket = this.context.ws;
            const familyId = localStorage.getItem('familyID');

            const notificationAcknowledgeRequest = {
                type: 'read_notification_acknowledgement', family_id: familyId, notification_id: id, is_hidden: clear
            };
            socket.send(JSON.stringify(notificationAcknowledgeRequest));

        }
        ;

    }

    handleNotificationClose = (id) => {

        this.clearNotificationFromServer(id, MAKE_HIDDEN)
        const updatedNotifications = this.context.notificationData.filter(notification => notification.ID !== id);
        this.context.updateNotificationBadge(updatedNotifications);

    }

    handleNotificationClick = (id) => {

        this.clearNotificationFromServer(id, MARK_READ)
        const updatedNotifications = this.context.notificationData.map(notification => {
            if (notification.ID === id) {

                return {...notification, timestamp_read: true};
            }
            return notification;
        });

        this.context.updateNotificationBadge(updatedNotifications);
    }


    render() {
        let {notificationData} = this.context;

        function formatTimestamp(timestamp) {
            const date = new Date(timestamp * 1000);
            const now = new Date();

            const diff = now - date;

            const hours = Math.floor(diff / (1000 * 60 * 60));

            if (hours < 1) {
                return `${Math.floor(diff / (1000 * 60))} minutes ago`;
            } else if (hours < 24) {
                return `${hours} hours ago`;
            } else if (hours < 48) {
                return 'Yesterday';
            } else {
                return date.toDateString().slice(0, 3) + " " + date.getDate() + " " + date.toLocaleString('default', {month: 'short'})
            }
        }

        return (<div>
            <Grid container alignItems="center" justifyContent="space-between" overflow='hidden' ref={this.gridRef}>
                <Grid item xs={1} sm={1} style={{padding: 12, display: 'flex', justifyContent: 'center'}}>
                    <Link to={`/#`} style={{color: 'white'}}>
                        <img alt="Return to Main Menu" src="./app-icons/chevron-left.svg"/>
                    </Link>
                </Grid>

                <Grid item xs={10} sm={10} style={{padding: 12, display: 'flex', justifyContent: 'center'}}>
                    <Typography variant="h6" component="div"
                                style={{
                                    textAlign: 'center', fontFamily: 'Manrope', fontWeight: '600', color: 'white'
                                }}>
                        Notifications
                    </Typography>
                </Grid>

                <Grid item xs={1} sm={1}></Grid>
            </Grid>


            {notificationData.length === 0 ? (<Typography variant="h5"
                                                          style={{
                                                              textAlign: 'center',
                                                              marginTop: '20px',
                                                              color: 'grey',
                                                              justifyContent: 'center'
                                                          }}>
                No notifications
            </Typography>) : (<div style={{maxHeight: '90vh', overflowY: 'auto'}}>
                <List>
                    {notificationData.map(notification => {
                        let displayDate = formatTimestamp(notification.timestamp)
                        return (<React.Fragment key={notification.ID}>
                            <Paper onClick={() => this.handleNotificationClick(notification.ID)}
                                   style={{
                                       backgroundColor: 'black',
                                       margin: '4px 8px 4px 8px',
                                       borderRadius: '4px',
                                       padding: '10px'
                                   }} elevation={2}>
                                <ListItem>
                                    <Badge variant="dot" invisible={notification.timestamp_read} sx={{
                                        '& .MuiBadge-dot': {backgroundColor: 'red'}, margin: '16px 16px 16px 4px'
                                    }}>
                                    </Badge>
                                    <ListItemText primary={<Typography
                                        style={{color: 'white'}}>{notification.message}</Typography>}
                                                  secondary={<Typography
                                                      style={{color: '#9e9e9e'}}>{displayDate}</Typography>}/>
                                    <IconButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.handleNotificationClose(notification.ID)
                                        }}
                                        edge="end"
                                        aria-label="delete"
                                        size="small"
                                        style={{color: 'white'}}
                                    >
                                        <CloseIcon/>
                                    </IconButton>

                                </ListItem>

                            </Paper>
                            <Divider/>
                        </React.Fragment>);
                    })}
                </List>
            </div>)}
        </div>);
    }
}

export default Notifications;
