import React from 'react';

const ErrorModal = ({message, onClose}) => {
    if (!message) return null;

    return (<div
        className="error-modal-backdrop"
        onClick={onClose}
        style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '999999',
        }}
    >
        <div
            className="error-modal-content"
            onClick={(e) => e.stopPropagation()}
            style={{
                maxWidth: '500px',
                maxHeight: '300px',
                background: '#2E2E2E',
                padding: '20px',
                borderRadius: '20px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '12px',
            }}
        >
            <div
                style={{
                    color: 'white',
                    fontSize: '16px',
                    fontFamily: 'Manrope',
                    fontWeight: '500',
                    textAlign: 'center',
                    wordWrap: 'break-word',
                    marginBottom: '12px',
                }}
            >
                Error
            </div>
            <div
                style={{
                    color: 'white',
                    fontSize: '16px',
                    fontFamily: 'Manrope',
                    textAlign: 'center',
                    wordWrap: 'break-word',
                }}
            >
                {message}
            </div>
            <div
                onClick={onClose}
                style={{
                    cursor: 'pointer',
                    marginTop: '12px',
                    padding: '10px 20px',
                    background: '#4D4D4D',
                    borderRadius: '12px',
                    textAlign: 'center',
                    color: 'white',
                    fontSize: '16px',
                    fontFamily: 'Manrope',
                    fontWeight: '600',
                }}
            >
                OK
            </div>
        </div>
    </div>);
};

export default ErrorModal;
