import React, {Component} from 'react'
import {Button, Dialog, DialogTitle, DialogContent} from "@mui/material"

class ChatOptionsDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.isChatOptionsOpen || false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isChatOptionsOpen !== this.props.isChatOptionsOpen) {
            this.setState({open: this.props.isChatOptionsOpen});
        }
    }

    handleClose = () => {
        this.setState({open: false});
        this.props.onClose();
    }


    render() {

        const {open} = this.state;

        const styles = {
            dialogTitle: {
                textAlign: 'center',
                color: 'white',
                fontSize: 16,
                fontFamily: 'Manrope',
                fontWeight: '500',
                wordWrap: 'break-word',
                padding: 12,
            }, button: {
                display: 'flex',
                color: "#ffffff",
                backgroundColor: "#48484D",
                fontFamily: 'Manrope',
                fontSize: 16,
                textTransform: "none",
                borderRadius: 12,
                padding: '9px 8px',
                width: '100%',
                marginBottom: 8,
            }, dialogIcon: {
                color: "#ffffff", marginRight: 8, width: 20, height: 20,
            },
        }

        return (<>
            <Dialog
                sx={{zIndex: 9999999999}}
                open={open} onClose={this.handleClose}
                aria-labelledby="dialog-title" aria-describedby="dialog-description"
                PaperProps={{
                    style: {
                        display: 'flex',
                        justifyContent: 'center',
                        borderRadius: "20px",
                        backgroundColor: "#2E2E2E",
                        boxShadow: "none",
                        width: "100%",
                        padding: "12px"
                    }
                }}>
                <DialogTitle id="dialog-title" sx={{padding: '0'}} style={styles.dialogTitle}>Options</DialogTitle>
                <DialogContent sx={{padding: '0'}}>

                    <Button onClick={() => this.props.handleMediaOpen()}
                            style={{...styles.button, justifyContent: 'left'}}><img style={styles.dialogIcon}
                                                                                    alt="Camera Icon"
                                                                                    src="./app-icons/camera.svg"/>Photo
                        & Video Library</Button>
                    <Button onClick={() => this.props.handleDocumentOpen()}
                            style={{...styles.button, justifyContent: 'left'}}><img style={styles.dialogIcon}
                                                                                    alt="Document Icon"
                                                                                    src="./app-icons/document.svg"/>Document</Button>
                    <Button onClick={this.handleClose} style={styles.button}>Cancel</Button>

                </DialogContent>
            </Dialog>
        </>);
    }
}

export default ChatOptionsDialog;