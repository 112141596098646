import React from 'react';
import {
    Typography, Grid, TextField, FormControlLabel, Checkbox, Switch, styled, Button, IconButton, Box,
} from "@mui/material";
import {AppContext} from './AppContext';
import {Link} from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import TaskAddedSuccessDialog from './TaskAddedSuccessDialog';
import "../styles/style.css";

const PreferenceSwitch = styled(Switch)(({theme}) => ({
    width: 50, height: 28, padding: 0, '& .MuiSwitch-switchBase': {
        padding: 4, '&.Mui-checked': {
            transform: 'translateX(20px)', color: '#fff', '& + .MuiSwitch-track': {
                backgroundColor: '#2F98A3', opacity: 1, border: 0,
            },
        }, '&.Mui-focusVisible .MuiSwitch-thumb': {
            border: '6px solid #fff',
        },
    }, '& .MuiSwitch-thumb': {
        width: 20, height: 20, backgroundColor: 'black',
    }, '& .MuiSwitch-track': {
        borderRadius: 16,
        backgroundColor: '#BFFF88',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

class TaskInputForm extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            taskText: "",
            isSecret: false,
            isBudget: false,
            budgetAmount: "",
            executionPreference: true,
            file: null,
            showAlert: false,
            alertMessageSuccess: false,
            topBarHeight: 0,
            inputFieldHeight: 0,
            isSubmitting: false,

        };
        this.topBar = React.createRef();
        this.inputField = React.createRef();

    }

    componentDidMount() {
        this.updateHeights();
        window.addEventListener('resize', this.updateHeights);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateHeights);
    }

    updateHeights = () => {
        const topBarHeight = this.topBar.current ? this.topBar.current.clientHeight : 0;
        const inputFieldHeight = this.inputField.current ? this.inputField.current.clientHeight : 0;


        this.setState({
            topBarHeight, inputFieldHeight,
        });
    }

    handleChange = (event) => {
        const {name, value, checked, type} = event.target;
        const actualValue = type === "checkbox" ? checked : value;
        this.setState({[name]: actualValue});
    }


    handleSubmit = async (event) => {
        event.preventDefault();
        if (this.state.taskText.trim() !== '') {
            this.setState({ isSubmitting: true });
            try {
                let data = await this.addTask();
                await this.sendMessage(data)
                this.setState({
                    taskText: "", budgetAmount: "", isSecret: false, isBudget: false, showAlert: true, isSubmitting: false 
                }, () => {
                });

            } catch (error) {
                this.setState({ isSubmitting: false }); 
                console.error("Failed to add task:", error);
            }
        }
    }

    bytesToSize = (bytes) => {
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
    };

    sendMessage = async (taskID) => {

        try {

            if (!this.state.file) {
                return;
            }

            const familyId = localStorage.getItem('familyID');
            const memberId = localStorage.getItem('memberID');

            let uploadedFile = null;
            let fileMetadata = null;

            if (this.state.file) {
                uploadedFile = await this.context.handleFileUpload(this.state.file);
                const {file} = this.state;
                fileMetadata = JSON.stringify({
                    file_name: file.name, file_type: file.type, file_size: this.bytesToSize(file.size),
                });
            }

            const messageData = {
                type: 'chat_message',
                member_id: memberId,
                family_id: familyId,
                task_id: taskID,
                secret: this.state.isSecret,
                message: '',
                uploaded_file: uploadedFile,
                file_metadata: fileMetadata,
            };


            this.context.ws.send(JSON.stringify(messageData));

            this.setState({file: null})


        } catch (error) {
            console.error("Error sending message:", error);
            this.setState({errorMessage: error.message, isMessageSending: false});
        }
    }


    addTask() {
        return new Promise((resolve, reject) => {
            const familyID = localStorage.getItem("familyID");
            const memberID = localStorage.getItem("memberID");
            const {taskText, isSecret, budgetAmount, executionPreference} = this.state;
            const actionText = executionPreference ? "Let Me Decide" : "Just Do It";
            fetch("https://hook.eu1.make.com/a0ajuviig6ymkesrj9mes2vl2btut8xk", {
                method: "POST", body: JSON.stringify({
                    "familyID": familyID,
                    "memberID": memberID,
                    "taskText": taskText,
                    "Secret": isSecret,
                    "Budget": budgetAmount,
                    "ExecutionPreference": actionText
                }),
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    return response.text();
                })
                .then(data => {
                    this.setState({alertMessageSuccess:true},()=>{
                        resolve(data);
                    })                
                })
                .catch(error => {
                    console.error("There was an error!", error);
                    this.setState({alertMessageSuccess:false},()=>{
                        this.setState({showAlert:true})
                    })
                    reject(error);
                });
        });
    }

    handleFileChange = (event) => {
        this.setState({file: event.target.files[0]});
    }

    removeFile = () => {
        this.setState({file: null});
    }


    render() {
        const {
            taskText, isSecret, isBudget, budgetAmount, executionPreference, showAlert, alertMessageSuccess, isSubmitting 
        } = this.state;


        const textFieldStyle = {
            background: '#202020',
            borderRadius: 25,
            border: '0.50px #48484D solid',
            justifyContent: 'flex-start',
            alignItems: 'center',
            fontFamily: "Manrope",
            fontWeight: "normal",
            flexGrow: 1,

        }

        const buttonStyle = {

            minWidth: 50,
            minHeight: 50,
            background: '#2F98A3',
            borderRadius: "50%",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 8,

        }
        const letMeDecideText = "I will ask for you to choose options or approve final purchase";
        const justDoItText = "I will  make the decision to get the task done and make the purchase if applicable";
        return (<>

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 0,
                overflowY: 'hidden',
                paddingBottom: 'env(safe-area-inset-bottom)'
            }}>

                <TaskAddedSuccessDialog
                    open={showAlert}
                    isSuccess={alertMessageSuccess}
                    onClose={() => this.setState({showAlert: false})}
                />

                <Box>
                    <Grid container ref={this.topBar} alignItems="center" justifyContent="space-between"
                          overflow="hidden">
                        <Grid item xs={1} sm={1} style={{padding: '10px', display: 'flex', justifyContent: 'center'}}>
                            <Link to={`/#`} style={{color: 'white'}}>
                                <img alt="Return to Main Menu" src="./app-icons/chevron-left.svg"/>
                            </Link>
                        </Grid>
                        <Grid item xs={1} sm={1}></Grid>
                    </Grid>
                </Box>


                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                    height: `calc(100vh - ${(this.state.topBarHeight + this.state.inputFieldHeight)}px)`,
                    paddingBottom: `${this.state.inputFieldHeight}px`
                }}>
                    <Grid item xs={12} style={{
                        boxSizing: 'border-box',
                        width: '100%',
                        padding: 8,
                        background: '#121214',
                        justifyContent: 'center',
                        alignItems: 'left',
                        display: 'flex',
                        flexDirection: 'column',

                    }}>

                        <Grid style={{width: '100%',}}>
                            <Typography sx={{
                                color: 'white',
                                textAlign: 'left',
                                fontSize: '18px',
                                marginBottom: '10px',
                                marginLeft: '15px'
                            }}>How can I help?</Typography>
                        </Grid>
                        <TextField
                            style={textFieldStyle}
                            placeholder={`• Add New Task\n• Ask a Question`}
                            value={taskText}
                            onChange={this.handleChange}
                            name="taskText"
                            fullWidth
                            multiline
                            minRows={4}
                            maxRows={14}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'transparent',
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    color: 'white', lineHeight: '2', fontSize: '18px'
                                }
                            }}
                        />
                        <Grid item xs={12} style={{
                            display: 'flex', justifyContent: 'start', textAlign: 'left', margin: '0px 0px 0px 10px'
                        }}>
                            <input
                                accept="image/*,.pdf,.doc,.docx,.svg,.png"
                                style={{display: 'none'}}
                                id="raised-button-file"
                                multiple
                                type="file"
                                onChange={this.handleFileChange}
                            />
                            <label htmlFor="raised-button-file">
                                <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                    style={{
                                        color: 'white', margin: '10px 0',
                                    }}
                                >
                                    <AttachFileIcon/>
                                    <Typography style={{marginLeft: '5px'}}>Attach File</Typography>
                                </IconButton>
                            </label>
                            {this.state.file && (<Typography
                                sx={{color: 'white', marginLeft: '10px', display: 'flex', alignItems: 'center'}}>
                                {this.state.file.name}
                                <IconButton
                                    onClick={this.removeFile}
                                    size="small"
                                    sx={{
                                        color: 'white', marginLeft: '5px',
                                    }}
                                >
                                    <CloseIcon/>
                                </IconButton>
                            </Typography>)}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} style={{
                        display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0px',
                    }}>
                        <div style={{
                            display: 'flex', flexDirection: 'column', alignItems: 'flex-start', maxWidth: '80%',
                        }}>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={isSecret}
                                    onChange={this.handleChange}
                                    name="isSecret"
                                    color="primary"
                                    sx={{
                                        color: 'white', '&.Mui-checked': {
                                            color: 'white',
                                        }, '& .MuiSvgIcon-root': {
                                            color: 'white', fontSize: '1.8rem',
                                        }
                                    }}
                                />}
                                label="Is this a secret task?"
                                sx={{
                                    color: 'white', '& .MuiTypography-root': {
                                        fontSize: '18px',
                                    }
                                }}
                                style={{color: 'white',}}
                            />
                            <FormControlLabel
                                control={<Checkbox
                                    checked={isBudget}
                                    onChange={this.handleChange}
                                    name="isBudget"
                                    sx={{
                                        color: 'white', '&.Mui-checked': {
                                            color: 'white',
                                        }, '& .MuiSvgIcon-root': {
                                            color: 'white', fontSize: '1.8rem',
                                        }
                                    }}
                                />}
                                label="Do you have a budget?"
                                sx={{
                                    color: 'white', '& .MuiTypography-root': {
                                        fontSize: '18px'
                                    }
                                }}
                                style={{color: 'white',}}
                            />
                        </div>
                    </Grid>
                    {isBudget && (<Grid style={{margin: '10px'}}>
                        <TextField
                            style={{
                                background: '#202020',
                                borderRadius: 25,
                                border: '0.50px #48484D solid',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                fontFamily: "Manrope",
                                fontWeight: "normal",
                            }}
                            placeholder="Add Details"
                            value={budgetAmount}
                            onChange={this.handleChange}
                            name="budgetAmount"
                            fullWidth
                            size='small'
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'transparent',
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    color: 'white',
                                }
                            }}
                        /></Grid>)}
                    <Grid container spacing={2} alignItems="center" justifyContent="center"
                          style={{marginTop: 20, marginBottom: 10}}>
                        <Typography sx={{color: 'white', marginRight: '20px', fontSize: '18px'}}>Just Do It</Typography>
                        <PreferenceSwitch checked={executionPreference} onChange={this.handleChange}
                                          name="executionPreference"/>
                        <Typography sx={{color: 'white', marginLeft: '20px', fontSize: '18px'}}>Let Me
                            Decide</Typography>
                    </Grid>
                    <Grid sx={{width: '100%', paddingRight: '50px', paddingLeft: '50px'}}>
                        <Typography sx={{color: 'grey', textAlign: 'center', fontSize: '1.1rem'}}>
                            {this.state.executionPreference ? letMeDecideText : justDoItText}
                        </Typography>
                    </Grid>


                </div>

                <Box>

                    <Grid ref={this.inputField} container
                          style={{paddingBottom: `env(safe-area-inset-bottom, 15px)`, position: 'fixed', bottom: 0}}>
                        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', padding: 15,}}>
                            <Button
                                style={{
                                    ...buttonStyle,
                                    width: '100%',
                                    height: '65px',
                                    borderRadius: '25px',
                                    textTransform: 'none',
                                    fontSize: '18px',
                                    color: 'white',
                                    backgroundColor: isSubmitting || this.state.taskText.trim() === ''  ? '#B1B1B1' : '#2F98A3'
                                }}
                                onClick={this.handleSubmit}
                                disabled={isSubmitting || taskText.trim() === ''} 
                            >Send</Button>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </>)
    }
}

export default TaskInputForm;