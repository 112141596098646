import React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Badge from '@mui/material/Badge';

class HamburgerMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null, familyID: localStorage.getItem('familyID') || null
        };
    }

    handleClick = (event) => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = () => {
        this.setState({anchorEl: null});
    };

    handleBlckBxExtras = () => {
        const url = `https://www.cognitoforms.com/Blckbx1/BlckBxExtras?entry={%22Metadata%22:{%22FamilyID%22:%22${this.state.familyID}%22}}`;
        window.open(url, '_blank');
        this.handleClose();
    };


    handleRefer = () => {
        this.props.onRefer();
        this.handleClose();
    };

    handleNotifications = () => {
        this.props.onNotifications();
        this.handleClose();
    };

    handleLogout = () => {
        this.props.onLogout();
        this.handleClose();
    };

    render() {
        const {anchorEl} = this.state;
        const open = Boolean(anchorEl);
        const notificationCount = this.props.unreadNotificationCount || 0;


        return (<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <IconButton
                style={{color: 'white'}}
                aria-label="menu"
                onClick={this.handleClick}
            >
                <Badge
                    badgeContent={notificationCount}
                    color="error"
                    anchorOrigin={{
                        vertical: 'top', horizontal: 'right',
                    }}
                >
                    <MenuIcon/>
                </Badge>

            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                style={{zIndex: '999997'}}
                keepMounted
                open={open}
                onClose={this.handleClose}
                sx={{
                    '& .MuiPaper-root': {
                        bgcolor: '#121214', color: 'white',
                    },
                }}
            >
                <MenuItem onClick={this.handleNotifications} style={{color: 'white'}}>
                    General Notifications {notificationCount > 0 ? ` (${notificationCount})` : ''}
                </MenuItem>
                <MenuItem onClick={this.handleRefer} style={{color: 'white'}}>
                    Refer a Friend
                </MenuItem>
                <MenuItem onClick={this.handleLogout} style={{color: 'white'}}>
                    Logout
                </MenuItem>
            </Menu>
        </div>);
    }
}

export default HamburgerMenu;
