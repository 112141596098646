import React, { Component } from 'react'
import { AppContext } from './AppContext';
import { Grid, TextField} from "@mui/material";
import HamburgerMenu from './HamburgerMenu';

class Navbar extends Component {
static contextType = AppContext;
constructor(props) {
    super(props);
    this.state = {
        searchText:''

    };
}


refer = () => {
    this.context.navigate('/refer');
};

notifications = () => {
    this.context.navigate('/notifications');
};

calculateNotificationCount = () => {

    const unreadNotifications = this.context.notificationData.filter(notification => !notification.timestamp_read);
    return unreadNotifications.length;
}

handleSearchChange = (event) => {
    this.setState({ searchText: event.target.value },()=>{

        if (this.props.tab === 'tasks'){
            this.props.onSearchChange(this.state.searchText)
        }
        else if(this.props.tab === 'done'){
            this.props.onDoneSearchChange(this.state.searchText)
        }
           
    });
   
};


  render() {

    const unreadNotificationCount = this.calculateNotificationCount();
    const {tab}=this.props;
    let placeholderText = "";

    if (tab === 'tasks') {
        placeholderText = "Search tasks...";
    } else if (tab === 'done') {
        placeholderText = "Search done tasks...";
    }
    return (
        <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={2} sm={1} style={{ paddingTop: 12, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <HamburgerMenu onRefer={this.refer} onNotifications={this.notifications} onLogout={this.props.onLogout}
                unreadNotificationCount={unreadNotificationCount} />
        </Grid>
        <Grid item xs={8} sm={10} md={10} style={{ display: 'flex', justifyContent: 'center' }}>
            <TextField
                fullWidth
                variant="outlined"
                placeholder={placeholderText}
                value={this.state.searchText}
                onChange={this.handleSearchChange}
                style={{  marginTop: 10, borderRadius: 15, background: '#202020', }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                            borderColor: 'transparent',
                        },
                         '& input': { 
                            color: 'white',
                        }
                    }                
                }}
            />
        </Grid>
        <Grid item xs={2} sm={1} md={1} style={{ display: 'flex', justifyContent: 'center' }}>
            <img src="icon-inverted.svg" alt="Logo" style={{ height: '52px', margin: '10px 10px 0px 10px' }} />
        </Grid>
        </Grid>
    )
  }
}

export default Navbar
